import { OneMin, ThreeMin, FiveMin, TenMin } from "../../../assets/images"
const TradingBox = ({ minute }) => {
  switch (minute) {
    case "1":
      return (
        <div className={`home-trading-box`}>
          <img src={OneMin} alt="" />
        </div>
      )
    case "3":
      return (
        <div className={`home-trading-box`}>
          <img src={ThreeMin} alt="" />
        </div>
      )
    case "5":
      return (
        <div className={`home-trading-box`}>
          <img src={FiveMin} alt="" />
        </div>
      )
    case "10":
      return (
        <div className={`home-trading-box`}>
          <img src={TenMin} alt="" />
        </div>
      )
    default:
      return ""
  }
}

export default TradingBox
