import React from "react";
import { MDBContainer } from "mdb-react-ui-kit";
import { CheckCircle } from "../../../assets/images";

const BetConfirm = ({ closeHandler }) => {
  return (
    <React.Fragment>
      <MDBContainer className="bet-confirm g-0" onClick={closeHandler}>
        <img src={CheckCircle} />
      </MDBContainer>
    </React.Fragment>
  );
};

export default BetConfirm;
