import React, { useEffect, useState } from "react"
import { MDBRow } from "mdb-react-ui-kit"
import { LineVector } from "../../../assets/images"
import apiServices from "../../../services/api.services"

const BetBubble = ({ minute, numbers }) => {
  return (
    <MDBRow className="bet-bubble-row g-0">
      <img src={LineVector} alt=""></img>
      <div className={numbers[0] >= 14 ? "bet-bubble-first green" : "bet-bubble-first red"}>{numbers[0]}</div>
      <div className={numbers[1] >= 14 ? "bet-bubble-second green" : "bet-bubble-second red"}>{numbers[1]}</div>
      <div className={numbers[2] >= 14 ? "bet-bubble-third green" : "bet-bubble-third red"}>{numbers[2]}</div>
      <div className="bet-bubble-fourth default"></div>
      <div className="bet-bubble-fifth default"></div>
    </MDBRow>
  )
}

export default BetBubble
