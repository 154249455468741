import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow } from "mdb-react-ui-kit";
import moment from "moment";

// Common Component //
import Header from "../../components/headers/header-three";
import SideBar from "../../components/sidebars/sidebar-one";
import ProtectedRoute from "../../components/common/protected.route";

// Local Component //
import AccountDetail from "./component/account.detail";
import History from "./component/history";
import ChangePassword from "./component/change.password";
import ChangeLanguage from "./component/change.language";
import ModalBox from "./component/modalbox";

//element
import Button from "./../../components/element/button";
import Navbar from "./../../components/element/navbar";
import Layout from "./../../components/common/layout";

// Image //
import { ProfileLogoV2 } from "./../../assets/images/index";

import Loader from "../../assets/images/Loader.svg";

// Services //
import authServices from "../../services/auth.services";
import apiServices from "../../services/api.services";
import { useNavigate } from "react-router-dom";
import packageJson from "./../../../package.json";
import { Trans, useTranslation } from "react-i18next";

const Profile = () => {
  const navigate = useNavigate();
  const { t } = useTranslation()
  const [showAddDetail, setShowAddDetail] = useState(false);
  const [accountDetail, setAccountDetail] = useState({});
  const [username, setUsername] = useState("");
  const [registerDate,setRegisterDate] = useState({})
  const [balance, setBalance] = useState(0);

  const closeAddDetail = () => {
    setShowAddDetail(!showAddDetail);
  };

  const showAdd = () => {
    setShowAddDetail(true);
  };

  useEffect(() => {
    getAccountDetailList();
    getWalletBalance()

  }, []);

  const getAccountDetailList = async () => {
    const response = await apiServices.getAccountDetail();
    if (response) {
      setAccountDetail(response.data);
      setRegisterDate(moment(response.data.register_at).format("DD-MM-YYYY"))
      setUsername(response.data.name);
    }
  };

  const getWalletBalance = async () => {
    const response = await apiServices.getWalletBalance();
    if (response) {
      setBalance(response.data.wallet_balance);
    }
  };

  const logoutEvent = () => {
    authServices.logout();
    navigate("/");
  };
  
  return (
    <React.Fragment>
        {showAddDetail && (
          <ModalBox
            show={showAddDetail}
            seShow={setShowAddDetail}
            closeHandler={closeAddDetail}
            accountDetail={accountDetail}
            getAccountDetail={getAccountDetailList}
          />
        )}
        <MDBContainer className="profile-main-container g-0">
          <ProtectedRoute />
          <Header username={username} balance={balance}/>
          <SideBar profileInfo={accountDetail} registerDate={registerDate} balance={balance}/>
          <Navbar />
          <Layout />
          <MDBContainer className="profile-container g-0">
            {Object.keys(accountDetail).length > 0 ? (
              <MDBContainer className="center">
                <img className="profile-logo" src={ProfileLogoV2} />
                <h6 style={{ color: "#A8A8A8" }}>MCT Trading Platform</h6>
                <MDBRow>
                  <h2 className="profile-username">{accountDetail.name}</h2>
                    <h6 className="profile-register-date">
                      {t("register at",{registerDate})} &nbsp; 
                      {/* {moment(accountDetail.register_at).format("DD-MM-YYYY")} */}
                    </h6>
                </MDBRow>
                <AccountDetail show={showAdd} accountDetail={accountDetail} />
                <History />
                <ChangeLanguage />
                <ChangePassword
                  accountDetail={accountDetail}
                  getAccountDetail={getAccountDetailList}
                />
                <div className="" onClick={() => logoutEvent()}>
                  <Button className="profile-logout-btn" label={t("LOG OUT")} />
                </div>
                <MDBRow className="d-block">v{packageJson.version}</MDBRow>
              </MDBContainer>
            ) : (
              <MDBRow className="loader-container">
                <img
                  className="loader"
                  src={Loader}
                  alt=""
                  width="100"
                  height="100"
                />
                {/* LOADING.... */}
              </MDBRow>
            )}
          </MDBContainer>
        </MDBContainer>
    </React.Fragment>
  );
};

export default Profile;
