import React, { useState, useEffect } from "react";
import { HomeSlide, ImageBank } from "./../../../assets/images/index";
import {
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBCarouselElement,
  MDBCarouselCaption,
  MDBRow,
} from "mdb-react-ui-kit";
import Loader from "../../../assets/images/Loader.svg";
import apiServices from "../../../services/api.services";

const Announcement = ({ className }) => {
  const [homeSlide, setHomeSlide] = useState();
  useEffect(() => {
    getHomeSlide();
  }, []);

  const getHomeSlide = async () => {
    const response = await apiServices.getPromotion();
    if (response) {
      setHomeSlide(response.data);
    }
  };
  // console.log("homeSlide", homeSlide);
  return (
    <MDBCarousel interval={10000} className={`${className} noselect`}>
      {homeSlide ? (
        <MDBCarouselInner>
          <MDBCarouselItem className="active">
            <MDBCarouselElement src={homeSlide[0].media} alt="..." />
          </MDBCarouselItem>
          {homeSlide.slice(1).map((media, index) => {
            return (
              <MDBCarouselItem key={index}>
                <MDBCarouselElement src={media.media} alt="..." />;
              </MDBCarouselItem>
            );
          })}
        </MDBCarouselInner>
      ) : (
        <MDBRow className="loader-container">
          <img className="loader" src={Loader} alt="" width="50" height="100" />
          {/* LOADING.... */}
        </MDBRow>
      )}
    </MDBCarousel>
  );
};

export default Announcement;
