import http from "./http.services";
import config from "../config.json";

const apiEndpoint = config.apiEndPoint + "/login";
const tokenKey = "token";

http.setToken(getToken());

async function login(username, password) {
  const { data } = await http.post(apiEndpoint, {
    username: username,
    password: password,
  });
  const token = data.data.token || "";
  localStorage.setItem(tokenKey, token);
  localStorage.setItem("user_id", data.data.id);
  if (token) return true;
  else return false;
}

function loginWithToken(data, username) {
  localStorage.setItem(tokenKey, data.token);
  localStorage.setItem("username", username);
  localStorage.setItem("trade_pin", "");
}

function logout() {
  // localStorage.clear();
  localStorage.removeItem("token");
  localStorage.removeItem("username");
  localStorage.removeItem("pusherTransportTLS");
}

function getCurrentUser() {
  const token = localStorage.getItem(tokenKey);
  if (token) return { token };
  else return "";
}

function getToken() {
  return localStorage.getItem(tokenKey);
}

async function register(data, code) {
  return await http.post(
    config.apiEndPoint + `/register?referral_code=${code}`,
    {
      phone_number: data.phone_number || "",
      password: data.password || "",
      invitation_code: data.invitation_code || "",
      confirm_password: data.confirm_password || "",
      username: data.username || "",
    }
  );
}

async function UpdateProfile(data) {
  const response = await http.post(config.apiEndPoint + "/update_profile", {
    name: data.fullname,
    account_number: data.account_number,
    payment_name: data.payment_name,
    phone_number: data.contact_number,
  });
  return response;
}

async function Topup(amount, file, bankID, formData) {
  const response = await http.post(config.apiEndPoint + "/topup", formData);
  return response;
}

async function FpxTopup(amount, formData) {
  const response = await http.post(config.apiEndPoint + "/pg_topup", formData);
  return response;
}

async function Withdrawal(values) {
  const response = await http.post(config.apiEndPoint + "/withdrawal", {
    amount: values.amount,
  });
  return response;
}

async function ChangePassword(data) {
  const response = await http.post(config.apiEndPoint + "/change_password", {
    password: data.password,
    new_password: data.new_password,
    confirm_new_password: data.confirm_new_password,
  });
  return response;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
  loginWithToken,
  logout,
  getCurrentUser,
  getToken,
  register,
  UpdateProfile,
  ChangePassword,
  FpxTopup,
  Topup,
  Withdrawal,
};
