import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
const MyReferral = ({ referralReport }) => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <MDBContainer>
        <MDBRow className="profile-box-row g-0">
          <MDBRow className="g-0">
            <div className="profile-box-label-1 ">{t("My Referral")}</div>
          </MDBRow>
          <MDBContainer className="profile-box">
            <MDBRow className="profile-box-row">
              <MDBCol className="col-6 profile-box-col noselect">
                {t("Total People")} :
              </MDBCol>
              <MDBCol className="col-6 profile-box-col2 noselect">
                {referralReport ? referralReport.total_people : ""}
              </MDBCol>
            </MDBRow>
            <div className="line"></div>
            <MDBRow className="profile-box-row">
              <MDBCol className="col-6 profile-box-col">
              {t("Level 1 People")} :
              </MDBCol>
              <MDBCol className="col-6 profile-box-col2">
                {referralReport ? referralReport.level_1_people : ""}
              </MDBCol>
            </MDBRow>
            <div className="line"></div>
            <MDBRow className="profile-box-row">
              <MDBCol className="col-6 profile-box-col">
              {t("Level 2 People")} :
              </MDBCol>
              <MDBCol className="col-6 profile-box-col2">
                {referralReport ? referralReport.level_2_people : ""}
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>
      </MDBContainer>
    </React.Fragment>
  );
};

export default MyReferral;
