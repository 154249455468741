import React, { useEffect } from "react";

const BetCustomCountdown = ({
  minutes,
  seconds,
  resetting,
  flipFlag,
  setFlipFlag,
  setSpin,
  setShouldStop,
  customStop,
}) => {
  // console.log(`CUSTOM M: ${minutes} S: ${seconds} RESET:${resetting}`);
  if (!flipFlag) {
  }

  useEffect(() => {
    if (minutes <= 0 && seconds == 0) {
      setFlipFlag(true);
      setSpin(true);
      setShouldStop(false);
    }
    // return () => {
    //   setFlipFlag(false);
    //   setSpin(true);
    //   setShouldStop(false);
    // };
  }, [minutes, seconds]);

  if (resetting || customStop) {
    // console.log(`calling bet result info `, resetting, flipFlag);

    // setCurrentBet({ duration: minute * 60 })
    // getBetResultInfo(minute)
    return (
      <div style={{ whiteSpace: "nowrap" }}>
        <span className="bet-timer">0</span>
        <span className="bet-timer">0</span>:
        <span className="bet-timer">0</span>
        <span className="bet-timer">0</span>
      </div>
    );
  } else {
    return (
      <div style={{ whiteSpace: "nowrap" }}>
        <span className="bet-timer">
          {Math.floor((minutes / 10) % 10) > 0
            ? Math.floor((minutes / 10) % 10)
            : "0"}
        </span>
        <span className="bet-timer">
          {Math.floor((minutes / 1) % 10) > 0
            ? Math.floor((minutes / 1) % 10)
            : "0"}
        </span>
        :
        <span className="bet-timer">
          {Math.floor((seconds / 10) % 10) > 0
            ? Math.floor((seconds / 10) % 10)
            : "0"}
        </span>
        <span className="bet-timer">
          {Math.floor((seconds / 1) % 10) > 0
            ? Math.floor((seconds / 1) % 10)
            : "0"}
        </span>
      </div>
    );
  }
};

export default BetCustomCountdown;
