import http from "./http.services";
import auth from "./auth.services";
import config from "../config.json";

const apiEndpoint = config.apiEndPoint;
const tokenKey = "token";

async function getWalletBalance() {
  http.setToken(auth.getToken());
  const response = await http.get(config.apiEndPoint + "/getWalletBalance");
  return response.data;
}

async function getHome() {
  http.setToken(auth.getToken());
  const response = await http.get(config.apiEndPoint + "/home");
  return response.data;
}

async function getAccountDetail() {
  http.setToken(auth.getToken());
  const response = await http.get(config.apiEndPoint + "/profile");
  return response.data;
}

async function getWalletHistory(length,start,startDate,endDate) {
  http.setToken(auth.getToken());
  if (start > 0) {
    const response = await http.get(
      config.apiEndPoint +
        `/wallet?length=${length}&start=${start}&startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
  } else if(length === 5){
    const response = await http.get(
      config.apiEndPoint +
        `/wallet?length=${length}`
    );
    return response.data;
  } else {
    const response = await http.get(
      config.apiEndPoint +
        `/wallet?length=${length}&startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
  }
}

async function getBetInfo(min) {
  http.setToken(auth.getToken());
  const response = await http.get(config.apiEndPoint + "/bet_info?type=" + min);
  return response.data;
}

async function postBet(payload) {
  http.setToken(auth.getToken());
  const response = await http.post(config.apiEndPoint + "/bet", payload);
  return response.data;
}

async function getProfile() {
  http.setToken(auth.getToken());
  const response = await http.get(config.apiEndPoint + "/profile");
  return response.data;
}

async function getTodayReport(startDate, endDate) {
  http.setToken(auth.getToken());
  const response = await http.get(
    config.apiEndPoint +
      `/report/today_report?startDate=${startDate}&endDate=${endDate}`
  );
  return response.data;
}

async function getTradeReport(start, end) {
  // console.log("start", start);
  // console.log("end", end);
  const length = 99999;
  http.setToken(auth.getToken());
  if (start === undefined && end === undefined) {
    const response = await http.get(
      config.apiEndPoint + `/report/trade_report?length=${length}`
    );
    return response.data;
  } else {
    const response = await http.get(
      config.apiEndPoint +
        `/report/trade_report?length=${length}&startDate=${start}&endDate=${end}`
    );
    return response.data;
  }
}

async function getReferral() {
  http.setToken(auth.getToken());
  const response = await http.get(
    config.apiEndPoint + "/report/referral_report"
  );
  return response.data;
}

async function getCompanyBankList() {
  http.setToken(auth.getToken());
  const response = await http.get(config.apiEndPoint + "/company_bank");
  return response.data;
}

async function getBankList() {
  http.setToken(auth.getToken());
  const response = await http.get(config.apiEndPoint + "/bank");
  return response.data;
}

async function getPromotion() {
  http.setToken(auth.getToken());
  const response = await http.get(config.apiEndPoint + "/promotion");
  return response.data;
}

export default {
  getWalletBalance,
  getHome,
  getBetInfo,
  getAccountDetail,
  getTodayReport,
  getTradeReport,
  getReferral,
  getWalletHistory,
  getCompanyBankList,
  getBankList,
  getPromotion,
  postBet,
  getProfile,
};
