import axios from "axios";
import { toast } from "react-toastify";
// import logger from "../services/log.services";

axios.interceptors.response.use(null, (error) => {
  const authError = error.response && error.response.status == 401;
  const exptectedError = error.response && error.response.status >= 404;
  const unexpectedError = error.response.status == 500;

  if (authError) {
    localStorage.clear();
    window.location.reload(false);
  }

  if (unexpectedError) {
    // eslint-disable-next-line default-case
    // logger.log(error);
    toast.error("An unexpected error occurred @contact support");
  }
  //this.props.history.push("/error500");
  //window.location = "/error500";
  return Promise.reject(error);
});

function setToken(token) {
  const language = localStorage.getItem("i18nextLng")
  axios.defaults.headers.common = {
    Authorization: `Bearer ${token}`,
    Accept: `application/json`,
    Language: language
  };
}

const httpService = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setToken,
};

export default httpService;
