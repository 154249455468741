import { MDBContainer, MDBRow, MDBIcon } from "mdb-react-ui-kit"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
const Header = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <MDBContainer className="register-header g-0">
      <MDBRow>
        <MDBIcon
          className="register-back-icon"
          fas
          size="lg"
          icon="angle-left"
          onClick={() => {
            navigate("/")
          }}
        />
      </MDBRow>
      <MDBRow className="register-text-two">
        <span className="register-main-text">{t("Register New Account")}</span>

        <span>{t("your personal data is our high priority concern to secure")}</span>
      </MDBRow>
    </MDBContainer>
  )
}

export default Header
