import React, { useState, useRef, useEffect } from "react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBInput,
  MDBBtn,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownLink,
} from "mdb-react-ui-kit";

import BetHistory from "./bet.history";
import { InfoIcon } from "./../../../assets/images";
import BetChip from "./bet.chip";
import { Chip } from "./../../../assets/images";
import _ from "lodash";
import useSound from "use-sound";
import chipWav from "./../../../assets/sound/chip.wav";
import apiServices from "../../../services/api.services";
import { sweetAlertHelper } from "./../../../helper/sweetalert.helper";
import { Trans, useTranslation } from "react-i18next";
import { setTranslate3d } from "rsuite/esm/List/helper/utils";

const BetPlace = ({
  show,
  minute,
  getWalletBalance,
  myBetHistory,
  prizeRate,
  getBetResultInfo,
  tableActive,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [placeBet, setPlaceBet] = useState({});
  const [bigChip, setBigChip] = useState(false);
  const [smallChip, setSmallChip] = useState(false);
  const [oddChip, setOddChip] = useState(false);
  const [evenChip, setEvenChip] = useState(false);
  const [resetFlag, setResetFlag] = useState(false);
  const [play] = useSound(chipWav);
  const [amount, setAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  // const [myBetHistory, setMyBetHistory] = useState({});
  // const [prizeRate, setPrizeRate] = useState([]);

  // const getBetHistory = async (minute) => {
  //   const { data: response } = await apiServices.getBetInfo(minute);
  //   if (response) {
  //     setMyBetHistory(response.my_bet_history);
  //     setPrizeRate(response.bet_prizes);
  //   }
  // };

  const handleBet = async () => {
    setPlaceBet((placeBet) => !placeBet);
    setPlaceBet((amount) => !amount);
    if (Object.keys(placeBet).length && amount) {
      if (Object.keys(placeBet).length > 0 && amount > 0) {
        const payload = {
          single_amount: amount,
          total_number: Object.keys(placeBet),
          type: minute,
        };
        try {
          const response = await apiServices.postBet(payload);
          if (response) {
            const message = response.message;
            play();
            handleReset();
            getWalletBalance()
            getBetResultInfo(minute);
            // setCounting(0);
            sweetAlertHelper({
              title: t("Notification"),
              html: t(`message`, { message }),
              showCancelButton: false,
              showConfirmButton: true,
              allowOutsideClick: false,
            });
          }
        } catch (ex) {
          // console.log("error",ex.response.data.errors)
          if (ex && ex.response.status === 422) {
            const message = ex.response.data.errors;
            if (message && Object.keys(message).length > 0) {
              play();
              handleReset();

              Object.keys(message).map((item, i) => {
                // setErrorMessage(message[item]);
                sweetAlertHelper({
                  title: t("Alert"),
                  html: message[item],
                  showCancelButton: false,
                  showConfirmButton: true,
                  allowOutsideClick: false,
                });
              });
            }
          }
        }
      } else {
        handleReset();
        sweetAlertHelper({
          title: t("Alert"),
          html: t(`Please enter amount & chip.`),
          showCancelButton: false,
          showConfirmButton: true,
          allowOutsideClick: false,
        });
      }
    } else {
      handleReset();
      sweetAlertHelper({
        title: t("Alert"),
        html: t(`Please enter amount & chip.`),
        showCancelButton: false,
        showConfirmButton: true,
        allowOutsideClick: false,
      });
    }
  };

  const handleClick = (number) => {
    play();
    if (_.get(placeBet, number) === true) {
      delete placeBet[number];
      setPlaceBet(placeBet);
    } else {
      _.set(placeBet, number, true);
    }
    handleChange(amount);
  };

  const handleChange = (amount) => {
    const invalidNumber = [".", "e"];
    if (invalidNumber.indexOf(amount) !== null) {
      setAmount(amount);
      const totalBet = _.filter(placeBet, (data, key) => {
        return data === true;
      });
      setTotalAmount(amount * totalBet.length);
    }
  };

  const handleReset = () => {
    play();
    setBigChip(false);
    setSmallChip(false);
    setOddChip(false);
    setEvenChip(false);
    setResetFlag(true);
    setPlaceBet({});
    inputRef.current.value = "";
    setAmount(0);
    setTotalAmount(0);
  };

  // useEffect(() => {
  //   getBetHistory(minute);
  // }, []);

  return (
    <MDBContainer className="bet-place-container g-0">
      <div className="bet-place-row g-0">
        <MDBRow>
          {/* <MDBCol className="bet-col-min"> */}
          {/* <MDBDropdown>
              <MDBDropdownItem>1 MIN TABLE</MDBDropdownItem>
            </MDBDropdown> */}
          {/* <span>1 MIN TABLE</span> */}
          <MDBDropdown className="bet-col-min">
            <MDBDropdownToggle>
              {minute} {t("Minute")}
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              {tableActive.map((listing)=>{
                 for (var key in listing){
                  return(
                    <MDBDropdownItem>
                      <MDBDropdownLink href={`/bet/${key}`}>{key} {t("Minute")}</MDBDropdownLink>
                    </MDBDropdownItem>
                  )
                 }
              })}
            </MDBDropdownMenu>
          </MDBDropdown>
          {/* <select>
              <option>1 MIN TABLE</option>
              <option>3 MIN TABLE</option>
            </select> */}
          {/* </MDBCol> */}
          <MDBCol
            className="bet-col-play"
            onClick={() => {
              show();
            }}
          >
            {t("How to Trade")} <img src={InfoIcon} alt="" />
          </MDBCol>
        </MDBRow>
        <MDBRow className="bet-row-text">
          <Trans>
            <span>
              {t("Analysis 3 Number Sum Total 0-13 as Small , 14-27 as Big")}
            </span>
          </Trans>
        </MDBRow>

        <MDBRow className="bet-row">
          <MDBCol
            onClick={() => {
              if (smallChip) {
                setSmallChip(false);
                delete placeBet["small"];
                setPlaceBet(placeBet);
              }
              setBigChip(bigChip ? false : true);
              handleClick("big");
            }}
            size="3"
            className="bet-special left-top-corner"
          >
            {bigChip ? (
              <img className="bet-special-chip" src={Chip} alt="" />
            ) : (
              <div>
                <div className="bet-special-text">Big</div>{" "}
                {prizeRate.big ? (
                  <span className="bet-special-rate">
                    x{parseFloat(prizeRate.big).toFixed(2)}
                  </span>
                ) : (
                  ""
                )}
              </div>
            )}
          </MDBCol>
          <MDBCol size="9" className="bet-row-number">
            <MDBRow>
              <BetChip
                number={0}
                rate={
                  prizeRate[0] ? "x" + parseFloat(prizeRate[0]).toFixed(2) : " "
                }
                size={3}
                className={`col-example`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              />
              {/* <BetChip
                number={0}
                rate={"x" + parseFloat(prizeRate[0]).toFixed(2)}
                size={3}
                className={`col-example`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              /> */}
              <BetChip
                number={1}
                rate={
                  prizeRate[1] ? "x" + parseFloat(prizeRate[1]).toFixed(2) : " "
                }
                size={3}
                className={`col-example`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              />
              <BetChip
                number={2}
                rate={
                  prizeRate[2] ? "x" + parseFloat(prizeRate[2]).toFixed(2) : " "
                }
                size={3}
                className={`col-example`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              />
              <BetChip
                number={3}
                rate={
                  prizeRate[3] ? "x" + parseFloat(prizeRate[3]).toFixed(2) : " "
                }
                size={3}
                className={`col-example right-top-corner`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              />
            </MDBRow>
            <MDBRow>
              <BetChip
                number={4}
                rate={
                  prizeRate[4] ? "x" + parseFloat(prizeRate[4]).toFixed(2) : " "
                }
                size={3}
                className={`col-example`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              />
              <BetChip
                number={5}
                rate={
                  prizeRate[5] ? "x" + parseFloat(prizeRate[5]).toFixed(2) : " "
                }
                size={3}
                className={`col-example`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              />
              <BetChip
                number={6}
                rate={
                  prizeRate[6] ? "x" + parseFloat(prizeRate[6]).toFixed(2) : " "
                }
                size={3}
                className={`col-example`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              />
              <BetChip
                number={7}
                rate={
                  prizeRate[7] ? "x" + parseFloat(prizeRate[7]).toFixed(2) : " "
                }
                size={3}
                className={`col-example`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              />
            </MDBRow>
          </MDBCol>
          <MDBCol
            onClick={() => {
              if (bigChip) {
                setBigChip(false);
                delete placeBet["big"];
                setPlaceBet(placeBet);
              }
              setSmallChip(smallChip ? false : true);
              handleClick("small");
              setPlaceBet(_.omit(placeBet, "big"));
            }}
            size="3"
            className="bet-special"
          >
            {smallChip ? (
              <img className="bet-special-chip" src={Chip} alt="" />
            ) : (
              <div>
                <div className="bet-special-text">Small</div>{" "}
                {prizeRate.big ? (
                  <span className="bet-special-rate">
                    x{parseFloat(prizeRate.small).toFixed(2)}
                  </span>
                ) : (
                  ""
                )}
              </div>
            )}
          </MDBCol>
          <MDBCol size="9" className="bet-row-number">
            <MDBRow>
              <BetChip
                number={8}
                rate={
                  prizeRate[8] ? "x" + parseFloat(prizeRate[8]).toFixed(2) : " "
                }
                size={3}
                className={`col-example`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              />
              <BetChip
                number={9}
                rate={
                  prizeRate[9] ? "x" + parseFloat(prizeRate[9]).toFixed(2) : " "
                }
                size={3}
                className={`col-example`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              />
              <BetChip
                number={10}
                rate={
                  prizeRate[10]
                    ? "x" + parseFloat(prizeRate[10]).toFixed(2)
                    : " "
                }
                size={3}
                className={`col-example`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              />
              <BetChip
                number={11}
                rate={
                  prizeRate[11]
                    ? "x" + parseFloat(prizeRate[11]).toFixed(2)
                    : " "
                }
                size={3}
                className={`col-example`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              />
            </MDBRow>
            <MDBRow className="bet-row-number">
              <BetChip
                number={12}
                rate={
                  prizeRate[12]
                    ? "x" + parseFloat(prizeRate[12]).toFixed(2)
                    : " "
                }
                size={3}
                className={`col-example`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              />
              <BetChip
                number={13}
                rate={
                  prizeRate[13]
                    ? "x" + parseFloat(prizeRate[13]).toFixed(2)
                    : " "
                }
                size={3}
                className={`col-example`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              />
              <BetChip
                number={14}
                rate={
                  prizeRate[14]
                    ? "x" + parseFloat(prizeRate[14]).toFixed(2)
                    : " "
                }
                size={3}
                className={`col-example`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              />
              <BetChip
                number={15}
                rate={
                  prizeRate[15]
                    ? "x" + parseFloat(prizeRate[15]).toFixed(2)
                    : " "
                }
                size={3}
                className={`col-example`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              />
            </MDBRow>
          </MDBCol>
          <MDBCol
            onClick={() => {
              if (evenChip) {
                setEvenChip(false);
                delete placeBet["even"];
                setPlaceBet(placeBet);
              }
              setOddChip(oddChip ? false : true);
              handleClick("odd");
            }}
            size="3"
            className="bet-special"
          >
            {oddChip ? (
              <img className="bet-special-chip" src={Chip} alt="" />
            ) : (
              <div>
                <div className="bet-special-text">Odd</div>{" "}
                {prizeRate.big ? (
                  <span className="bet-special-rate">
                    x{parseFloat(prizeRate.odd).toFixed(2)}
                  </span>
                ) : (
                  ""
                )}
              </div>
            )}
          </MDBCol>
          <MDBCol size="9" className="bet-row-number">
            <MDBRow>
              <BetChip
                number={16}
                rate={
                  prizeRate[16]
                    ? "x" + parseFloat(prizeRate[16]).toFixed(2)
                    : " "
                }
                size={3}
                className={`col-example`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              />
              <BetChip
                number={17}
                rate={
                  prizeRate[17]
                    ? "x" + parseFloat(prizeRate[17]).toFixed(2)
                    : " "
                }
                size={3}
                className={`col-example`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              />
              <BetChip
                number={18}
                rate={
                  prizeRate[18]
                    ? "x" + parseFloat(prizeRate[18]).toFixed(2)
                    : " "
                }
                size={3}
                className={`col-example`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              />
              <BetChip
                number={19}
                rate={
                  prizeRate[19]
                    ? "x" + parseFloat(prizeRate[19]).toFixed(2)
                    : " "
                }
                size={3}
                className={`col-example`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              />
            </MDBRow>
            <MDBRow className="bet-row-number">
              <BetChip
                number={20}
                rate={
                  prizeRate[20]
                    ? "x" + parseFloat(prizeRate[20]).toFixed(2)
                    : " "
                }
                size={3}
                className={`col-example`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              />
              <BetChip
                number={21}
                rate={
                  prizeRate[21]
                    ? "x" + parseFloat(prizeRate[21]).toFixed(2)
                    : " "
                }
                size={3}
                className={`col-example`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              />
              <BetChip
                number={22}
                rate={
                  prizeRate[22]
                    ? "x" + parseFloat(prizeRate[22]).toFixed(2)
                    : " "
                }
                size={3}
                className={`col-example`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              />
              <BetChip
                number={23}
                rate={
                  prizeRate[23]
                    ? "x" + parseFloat(prizeRate[23]).toFixed(2)
                    : " "
                }
                size={3}
                className={`col-example`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              />
            </MDBRow>
          </MDBCol>
          <MDBCol
            onClick={() => {
              if (oddChip) {
                setOddChip(false);
                delete placeBet["odd"];
                setPlaceBet(placeBet);
              }
              handleClick("even");
              setEvenChip(evenChip ? false : true);
            }}
            size="3"
            className="bet-special left-bottom-corner"
          >
            {evenChip ? (
              <img className="bet-special-chip" src={Chip} alt="" />
            ) : (
              <div>
                <div className="bet-special-text">Even</div>{" "}
                {prizeRate.big ? (
                  <span className="bet-special-rate">
                    x{parseFloat(prizeRate.even).toFixed(2)}
                  </span>
                ) : (
                  ""
                )}
              </div>
            )}
          </MDBCol>
          <MDBCol size="9" className="bet-row-number">
            <MDBRow>
              <BetChip
                number={24}
                rate={
                  prizeRate[24]
                    ? "x" + parseFloat(prizeRate[24]).toFixed(2)
                    : " "
                }
                size={3}
                className={`col-example`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              />
              <BetChip
                number={25}
                rate={
                  prizeRate[25]
                    ? "x" + parseFloat(prizeRate[25]).toFixed(2)
                    : " "
                }
                size={3}
                className={`col-example`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              />
              <BetChip
                number={26}
                rate={
                  prizeRate[26]
                    ? "x" + parseFloat(prizeRate[26]).toFixed(2)
                    : " "
                }
                size={3}
                className={`col-example`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              />
              <BetChip
                number={27}
                rate={
                  prizeRate[27]
                    ? "x" + parseFloat(prizeRate[27]).toFixed(2)
                    : " "
                }
                size={3}
                className={`col-example`}
                handleClick={handleClick}
                placeBet={placeBet}
                resetFlag={resetFlag}
              />
            </MDBRow>
            <MDBRow>
              <MDBCol
                size="12"
                className="col-example right-bottom-corner padding-1 g-0"
              >
                <MDBRow>
                  <MDBCol className="input-text">
                    <span>{t("Single Amount")}:</span>
                    <br />
                    <span className="input-text-amount">
                      {t("Total", { totalAmount })}
                    </span>
                  </MDBCol>
                  <MDBCol className="input-field">
                    <MDBInput
                      labelStyle={{ color: "grey" }}
                      label={t("Amount")}
                      id="input-amount"
                      type="number"
                      size="sm"
                      inputRef={inputRef}
                      // pattern="[0-9]*"
                      // step="1"
                      onKeyUp={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                        console.log("keyup",e.target.value)
                        handleChange(e.target.value);
                      }}
                      // onChange={(e) => {
                      //   console.log("e",e.target.value)
                      //   handleChange(e.target.value);
                      // }}
                    />
                    <span style={{ color: "red", fontSize: "0.7em" }}>
                      {errorMessage ? errorMessage : ""}
                    </span>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              {/* <MDBCol
                size="3"
                className="col-example skip-left-border skip-right-border"
              ></MDBCol>
              <MDBCol
                size="3"
                className="col-example skip-left-right-border"
              ></MDBCol>
              <MDBCol
                size="3"
                className="col-example skip-left-right-border"
              ></MDBCol>
              <MDBCol
                size="3"
                className="col-example skip-left-border right-bottom-corner"
              ></MDBCol> */}
            </MDBRow>
          </MDBCol>

          <MDBRow className="bet-row-confirmation">
            <MDBBtn
              className="bet-reset-btn"
              onClick={() => {
                handleReset();
              }}
            >
              {t("Reset")}
            </MDBBtn>
            <MDBBtn
              className="bet-confirm-btn"
              onClick={() => {
                handleBet();
              }}
            >
              {t("Confirm")}
            </MDBBtn>
          </MDBRow>
        </MDBRow>
      </div>

      <BetHistory myBetHistory={myBetHistory} />
    </MDBContainer>
  );
};

export default BetPlace;
