import React, { useState } from "react";
import { MDBCol } from "mdb-react-ui-kit";
import _ from "lodash";
import { Chip } from "./../../../assets/images";

const BetChip = ({
  size,
  className,
  number,
  rate,
  handleClick,
  placeBet,
  resetFlag,
}) => {
  const [display, setDisplay] = useState(false);

  return (
    <MDBCol
      onClick={() => {
        handleClick(number);
        setDisplay(display ? false : true);
      }}
      size={size}
      className={`g-0 ${className}`}
    >
      {_.get(placeBet, number) === true ? (
        <img className="bet-chip" src={Chip} alt="" />
      ) : (
        <div>
          <span className="bet-chip-number">{number}</span>
          <span className="bet-chip-rate">{rate}</span>
        </div>
      )}
    </MDBCol>
  );
};

export default BetChip;
