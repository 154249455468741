import { showReportDialog } from "@sentry/react";
import React, { useState, useRef } from "react";
import { useEffect } from "react";
import NewsTicker, { TickerStates } from "react-advanced-news-ticker";

const BetNumber = ({
  number,
  flipFlag,
  shouldStop,
  setShouldStop,
  spin,
  initialSpeed,
  openNumber,
}) => {
  const ref = useRef(null);
  const [speedAdd, setSpeedAdd] = useState(1);
  const [speed, setSpeed] = useState(initialSpeed);
  const [duration, setDuration] = useState(0);
  const [currentState, setCurrentState] = useState(TickerStates.Stopped);
  const [hit, setHit] = useState("");

  useEffect(() => {
    validateFlag();
  }, [shouldStop, flipFlag, spin]);

  // console.log("shouldStop", shouldStop);
  // console.log("flipFlag", flipFlag);
  // console.log("spin", spin);
  const validateFlag = () => {
    // console.log(`Should I flip : ${flipFlag}`);
    if (spin && ref.current) {
      setSpeed(1);
      setSpeedAdd(1);
      setDuration(0);
      ref.current.startButtonClick();
      setCurrentState(TickerStates.Running);
    }
  };

  if (flipFlag) {
    return (
      <NewsTicker
        className="bet-prize-box"
        rowHeight={50}
        maxRows={1}
        duration={duration}
        speed={speed}
        ref={ref}
        autoStart={false}
        pauseOnHover={false}
        hasMoved={() => {
          // console.log(`Moving : ${shouldStop} S: ${speed}`)
          if (speed > 600) {
            if (ref.current) {
              var item = ref.current.state.items[0];
            }

            // console.log(`Item : ${item}`)
            if (ref.current === openNumber) {
              ref.current.stop();
            }
            // ref.current.stopButtonClick()
            // if (ref.current.state.items[0] === number) {
            //   setSpeed(1)
            //   setSpeedAdd(1)
            //   setDuration(0)
            //   setCurrentState(TickerStates.Stopped)
            //   setShouldStop(false)
            //   setHit(ref.current.state.items[0])
            // }
          } else if (shouldStop) {
            // console.log(`OTHERS :  ${shouldStop}`)
            setSpeedAdd(speedAdd * 1.4);
            setSpeed(speed + speedAdd);
            setDuration(speed + 25);
          }
        }}
      >
        <div>0</div>
        <div>1</div>
        <div>2</div>
        <div>3</div>
        <div>4</div>
        <div>5</div>
        <div>6</div>
        <div>7</div>
        <div>8</div>
        <div>9</div>
      </NewsTicker>
    );
  } else {
    return <span className="bet-prize-box">{number}</span>;
  }
};

export default BetNumber;
