import React, { useState } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import ScrollTop from "./../../../components/common/scroll.top";
import { useEffect } from "react";
import apiServices from "../../../services/api.services";
import { useTranslation } from "react-i18next";

const Bet = ({ myBetHistory }) => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <MDBContainer className="bet-history-container">
        <MDBRow className="bet-history-container title">
          {t("My Trade History")}
        </MDBRow>
        <MDBContainer className="bet-history-box bg">
          <MDBContainer className="bet-history-content">
            <MDBRow>
              <MDBCol className="col-3 box label">{t("Prize No")}</MDBCol>
              <MDBCol className="col-3 box label">{t("Trade Amount")}</MDBCol>
              <MDBCol className="col-3 box label">{t("Result")}</MDBCol>
              <MDBCol className="col-3 box label">{t("Detail")}</MDBCol>
            </MDBRow>

            {myBetHistory.length > 0 ? (
              myBetHistory.map((data, index) => (
                <div key={index}>
                  <Link to="/history">
                    <MDBRow key={`bet-history-${index}`}>
                      <MDBCol
                        className="col-3 p-1 box"
                        style={{ wordBreak: "break-word" }}
                      >
                        {data.prize_no}
                      </MDBCol>
                      
                      <MDBCol className="col-3 box">{data.bet_amount}</MDBCol>
                      <MDBCol className="col-3 box">                        
                      {data.win_amount === "0" ? (
                          <>
                            <span className="bold-text">
                              {data.bet_result}
                            </span>
                            <br />
                            {t("Lose")}
                          </>
                        ) : data.win_amount === "" ? (
                          <>
                            {t("Pending")}
                          </>
                        ) : (
                          <>
                            <span className="bold-text">
                              {data.bet_result}
                            </span>
                            <br />
                            {t("Win")}
                            <br />
                            {data.win_amount}
                          </>
                        )}</MDBCol>
                      <MDBCol className="col-3 box">
                        <MDBRow className={`main-bet`}>
                          {data.detail
                            ? data.detail.map((detailData, detailIndex) => (
                                <MDBCol
                                  key={`bet-history-detail-${detailIndex}`}
                                  className="col bet"
                                >
                                  <div
                                    className={`ball ${
                                      detailData === "big"
                                        ? "green"
                                        : detailData === "small"
                                        ? "red"
                                        : detailData === "odd"
                                        ? "green"
                                        : detailData === "even"
                                        ? "red"
                                        : "white"
                                    }`}
                                  >
                                    {detailData === "big"
                                      ? "B"
                                      : detailData === "small"
                                      ? "S"
                                      : detailData === "odd"
                                      ? "O"
                                      : detailData === "even"
                                      ? "E"
                                      : detailData}
                                  </div>
                                </MDBCol>
                              ))
                            : ""}
                        </MDBRow>
                        {/* <MDBRow style={{ padding: "0.5em" }}>
                        <MDBCol className="col bet">
                          <div className="ball red">E</div>
                        </MDBCol>
                        <MDBCol className="col bet">
                          <div className="ball white">27</div>
                        </MDBCol>
                        <MDBCol className="col bet">
                          <div className="ball green">S</div>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow style={{ padding: "0.5em" }}>
                        <MDBCol className="col bet">
                          <div className="ball red">E</div>
                        </MDBCol>
                        <MDBCol className="col bet">
                          <div className="ball white">27</div>
                        </MDBCol>
                        <MDBCol className="col bet">
                          <div className="ball green">S</div>
                        </MDBCol>
                      </MDBRow> */}
                      </MDBCol>
                    </MDBRow>
                  </Link>
                </div>
              ))
            ) : (
              <MDBRow>
                <MDBCol className="p-4 border">{t("No Recent Activity")}...</MDBCol>
              </MDBRow>
            )}
            {/* <MDBRow>
              <MDBCol className="box">202203121</MDBCol>
              <MDBCol className="box">10.00</MDBCol>
              <MDBCol className="box">
                <MDBRow style={{ padding: "0.5em" }}>
                  <MDBCol className="col bet">
                    <div className="ball red">E</div>
                  </MDBCol>
                  <MDBCol className="col bet">
                    <div className="ball white">27</div>
                  </MDBCol>
                  <MDBCol className="col bet">
                    <div className="ball green">S</div>
                  </MDBCol>
                </MDBRow>
                <MDBRow style={{ padding: "0.5em" }}>
                  <MDBCol className="col bet">
                    <div className="ball red">E</div>
                  </MDBCol>
                  <MDBCol className="col bet">
                    <div className="ball white">27</div>
                  </MDBCol>
                  <MDBCol className="col bet">
                    <div className="ball green">S</div>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow> */}
            {/* <MDBRow>
              <MDBCol className="box">20220123</MDBCol>
              <MDBCol className="box">2.00 - L</MDBCol>
              <MDBCol className="box"></MDBCol>
            </MDBRow> */}
          </MDBContainer>
        </MDBContainer>
      </MDBContainer>
    </React.Fragment>
  );
};

export default Bet;
