import React, { useState, useEffect } from "react"
import { Big, Small } from "../../../assets/images"
import _ from "lodash"

const Box = ({ images, row, col, keyValue }) => {
  const [className, setClassName] = useState("")

  useEffect(() => {
    detectCorner()
    // console.log(`KEY VALUE : ${JSON.stringify(keyValue)}`)
  })

  const detectCorner = () => {
    // Border Radius
    // 1 - 1 (left top corner)
    // 1 - 7 (right top corner)
    // 7 - 1 (left bottom corner)
    // 7 - 7 (right bottom corner)

    if (row === 1 && col === 1) {
      setClassName("left-top-corner")
    } else if (row === 1 && col === 7) {
      setClassName("right-top-corner")
    } else if (row === 7 && col === 1) {
      setClassName("left-bottom-corner")
    } else if (row === 7 && col === 7) {
      setClassName("right-bottom-corner")
    }
  }

  return (
    <div className={`home-result-box p-2 ${className}`}>
      {_.get(keyValue, "value") === "big" ? (
        <img src={Big} alt="" />
      ) : _.get(keyValue, "value") === "small" ? (
        <img src={Small} alt="" />
      ) : (
        <div className={`home-empty-box`}>
          <span></span>
        </div>
      )}
    </div>
  )
}

export default Box
