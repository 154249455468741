import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
} from "mdb-react-ui-kit";
import ScrollTop from "./../../../components/common/scroll.top";
import BetNumber from "./bet.number";
import { Trans,useTranslation } from "react-i18next";

const BetHowToPlay = ({ closeHandler }) => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <MDBContainer
        className="bet-how-to-play-container"
        onClick={closeHandler}
      >
        <MDBCard className="bet-how-to-play-card">
          <MDBRow className="bet-how-to-play-card header">{t("How to Trade ?")}</MDBRow>
          <MDBCardHeader className="bet-how-to-play-card body">
            <label>{t("Read before start any trade")}</label>
            <span></span>
          </MDBCardHeader>
          <MDBCardBody className="bet-how-to-play-card body">
            <Trans>
              <ul>
                <li>{t("0-13=Small 14-27=Big")}</li>
                <li>{t("1-3-5-7-9=Odd <br>(e.g. trade result 173=11 Odd)")}</li>
                <li>{t("0-2-4-6-8=Even <br>(e.g. trade result 178=16 Even)")}</li>
                <li>{t("The sum of the three number is equal to the current trade result.")}</li>
                <li>{t("The trade numbers 0-27 <br>(for example, the current trade result 737=17 if the purchase amount is RM1, the 17 trade number will earn RM10.50, etc.) Each trade number has different rewards")}</li>
                <li>{t("All trade trancsactions must be completed before the specified time counts down")}</li>
                <li>{t("The minimum transaction amount per trade is RM1, and the maximum transaction amount is RM100,000.")}</li>
                <li>{t("If the transaction trade is Big and Small or Odd and Even at the same time , the system will automatically increase the turnover to prevent criminals from conducting money laundering activities.")}</li>
              </ul>
            </Trans>
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    </React.Fragment>
  );
};

export default BetHowToPlay;
