import React, { useState } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import _ from "lodash";

// Customize Library //
import formikHelper from "../../../helper/formik.helper";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";

// Service //
import authServices from "../../../services/auth.services";
import { Trans,useTranslation } from "react-i18next"

const ChangePassword = ({ accountDetail, getAccountDetail }) => {
  const {t} = useTranslation()
  const [data, setData] = useState({
    password: "",
    new_password: "",
    confirm_new_password: "",
  });

  const schema = Yup.object({
    password: Yup.string().required(t("Enter current password")),
    new_password: Yup.string()
      .required(t("Enter new password"))
      .min(8, t("Please enter more than 8 character"))
      .matches(
        /^[a-zA-Z0-9@!#$%&*^]+$/,
        t("Password cannot contain white space")
        // /^(?=.*[a-z A-Z 0-9])(?=.{8,})/,
        // "Must be at least 8 characters"
      ),
    confirm_new_password: Yup.string()
      .required(t("Enter confirm password"))
      .oneOf([Yup.ref("new_password"), null], t("Must same with New password")),
  });

  async function onSubmit(
    data,
    setFieldError,
    setSubmitting,
    resetForm,
    defaultData,
    getAccountDetail
  ) {
    try {
      const { data: response } = await authServices.ChangePassword({ ...data });
      if (response) {
        const message = response.message
        sweetAlertHelper({
          title: t("Notification"),
          html: t(`message`,{message}),
          showCancelButton: false,
          showConfirmButton: true,
          allowOutsideClick: false,
        });
        resetForm({ defaultData });
        getAccountDetail();
      }
    } catch (ex) {
      if (ex.response.data.status === "false") {
        const message = ex.response.data.message
        sweetAlertHelper({
          title: t("Notification"),
          html: t(`message`,{message}),
          showCancelButton: false,
          showConfirmButton: true,
          allowOutsideClick: false,
        });
        resetForm({ defaultData });
      }
    }
    setSubmitting(false);
  }
  return (
    <MDBContainer>
      <Formik
        initialValues={data}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting, setFieldError, resetForm }) => {
          onSubmit(
            values,
            setFieldError,
            setSubmitting,
            resetForm,
            getAccountDetail,
            data
          );
        }}
      >
        {({ errors, isSubmitting, isValid, readOnly }) => (
          <Form>
            <MDBRow className="profile-box-row">
              <MDBRow className="g-0">
                <div className="profile-box-label-1">{t("CHANGE PASSWORD")}</div>
              </MDBRow>
              <MDBContainer className="profile-box">
                <MDBRow className="profile-box-row">
                  <MDBCol className="col-5 profile-box-col">
                    {t("Current Password")} :
                  </MDBCol>
                  <MDBCol className="col-7 profile-box-col2">
                    {formikHelper.renderInput(
                      "password",
                      "",
                      "password",
                      "",
                      "",
                      false,
                      errors
                    )}
                  </MDBCol>
                </MDBRow>
                <div className="line"></div>
                <MDBRow className="profile-box-row">
                  <MDBCol className="col-5 profile-box-col">
                    {t("New Password")} :
                  </MDBCol>
                  <MDBCol className="col-7 profile-box-col2">
                    {formikHelper.renderInput(
                      "new_password",
                      "",
                      "password",
                      "",
                      "",
                      false,
                      errors
                    )}
                  </MDBCol>
                </MDBRow>
                <div className="line"></div>
                <MDBRow className="profile-box-row">
                  <MDBCol className="col-5 profile-box-col">
                    {t("Retype Password")} :
                  </MDBCol>
                  <MDBCol className="col-7 profile-box-col2">
                    {formikHelper.renderInput(
                      "confirm_new_password",
                      "",
                      "password",
                      "",
                      "",
                      false,
                      errors
                    )}
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
              <MDBRow className="profle-box-comment">
                {t("*password must be same with new password")}
              </MDBRow>
            </MDBRow>
            {formikHelper.renderButton(
              t("save"),
              isSubmitting,
              isValid,
              "profile-save-btn"
            )}
          </Form>
        )}
      </Formik>
    </MDBContainer>
  );
};

export default ChangePassword;
