import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import moment from "moment";

// Common Component //
import Header from "../../components/headers/header-three";
import SideBar from "../../components/sidebars/sidebar-one";
import ProtectedRoute from "../../components/common/protected.route";
import formikHelper from "../../helper/formik.helper";
import ModalBox from "./component/modalbox";

// Helper //
import { sweetAlertHelper } from "../../helper/sweetalert.helper";

// Image //
import Layout from "../../components/common/layout";
import Navbar from "../../components/element/navbar";
import Loader from "../../assets/images/Loader.svg";

// Service //
import authServices from "../../services/auth.services";
import apiServices from "../../services/api.services";
import { useTranslation } from "react-i18next";
const CashOut = () => {
  const { t } = useTranslation()
  const [showAddDetail, setShowAddDetail] = useState(false);
  const [accountDetail, setAccountDetail] = useState({});
  const [username, setUsername] = useState("");
  const [registerDate, setRegisterDate] = useState({});
  const [reload, setReload ] = useState(false)
  const [balance, setBalance] = useState(0);

  const closeAddDetail = () => {
    setShowAddDetail(false);
  };

  const getAccountDetailList = async () => {
    const response = await apiServices.getAccountDetail();
    if (response) {
      setAccountDetail(response.data);
      setRegisterDate(moment(response.register_at).format("ddd MMM DD YYYY"));
      setUsername(response.data.name);
    }
  };

  const getWalletBalance = async () => {
    const response = await apiServices.getWalletBalance();
    if (response) {
      setBalance(response.data.wallet_balance);
    }
  };

  useEffect(() => {
    getAccountDetailList();
  }, []);

  useEffect(() => {
    getWalletBalance();
  }, [reload]);

  const schema = Yup.object({
    amount: Yup.number()
      .required(t("required"))
      // .matches(/^\d+$/,t("you must specify a number"))
      .typeError(t("you must specify a number"))
      .test("amount", t("minimum RM20"), (value) => value >= 20),
  });

  async function onSubmit(
    values,
    setFieldError,
    setSubmitting,
    setReload,
    resetForm,
    defaultData
  ) {
    try {
      const { data: response } = await authServices.Withdrawal({ ...values });
      if (response) {
        const message = response.message
        setReload(!reload)
        sweetAlertHelper({
          title: t("Notification"),
          html: t(`message`,{message}),
          showCancelButton: false,
          showConfirmButton: true,
          allowOutsideClick: false,
        });
      }
    } catch (ex) {
      if (ex && ex.response.status === 422) {
        const errors = ex.response.data.errors;
        // toast.dismiss()
        if (errors && Object.keys(errors).length > 0) {
          // eslint-disable-next-line array-callback-return
          Object.keys(errors).map((item, i) => {
            setFieldError(item, errors[item]);
          });
        }
      } else {
        const message = ex.response.data.message
        sweetAlertHelper({
          title: t("Notification"),
          html: t(`message`,{message}),
          showCancelButton: false,
          showConfirmButton: true,
          allowOutsideClick: false,
        });
      }
    }
    setSubmitting(false);
  }
  return (
    <React.Fragment>
      {showAddDetail && (
        <ModalBox
          show={showAddDetail}
          closeHandler={closeAddDetail}
          accountDetail={accountDetail}
          getAccountDetail={getAccountDetailList}
        />
      )}
      <MDBContainer className="wallet-container">
        <ProtectedRoute />
        <Layout />
        <Header reload={reload} username={username} balance={balance}/>
        <SideBar profileInfo={accountDetail} registerDate={registerDate} balance={balance}/>
        <Navbar />
        {/* <AddDetail show={showAddDetail} closeHandler={closeAddDetail} /> */}
        <MDBContainer style={{ paddingTop: "5em", paddingBottom: "10em" }}>
          <MDBRow>
            <MDBCol className="wallet-title">{t("Withdrawal Request")}</MDBCol>
          </MDBRow>
          {Object.keys(accountDetail).length > 0 ? (
            <>
              <Formik
                enableReinitialize={true}
                validateOnChange={true}
                initialValues={{
                  bank: accountDetail.bank_type || "",
                  account: accountDetail.bank_account_number || "",
                  holder_name: accountDetail.fullname || "",
                  amount: "",
                }}
                validationSchema={schema}
                onSubmit={(
                  values,
                  { setSubmitting, setFieldError, resetForm }
                ) => {
                  onSubmit(values, setFieldError, setSubmitting,setReload);
                  resetForm({ values: "" });
                }}
              >
                {({
                  setFieldValue,
                  errors,
                  isSubmitting,
                  isValid,
                  handleReset,
                }) => (
                  <Form>
                    <MDBContainer className="wallet-box">
                      <MDBRow className="wallet-box-row">
                        <MDBCol className="col-5 wallet-box-label">
                          {t("Bank Name")} :
                        </MDBCol>
                        <MDBCol>
                          {accountDetail.bank_type ? (
                            <>
                              {formikHelper.renderInput(
                                "bank",
                                "",
                                "text",
                                "off",
                                "",
                                true,
                                errors
                              )}
                            </>
                          ) : (
                            <label
                              className="wallet-box-addbtn"
                              onClick={() => {
                                setShowAddDetail(true);
                              }}
                            >
                              + {t("Add Detail")}
                            </label>
                          )}
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="wallet-box-row">
                        <MDBCol className="col-5 wallet-box-label">
                          {t("Account")} :
                        </MDBCol>
                        <MDBCol>
                          {formikHelper.renderInput(
                            "account",
                            "",
                            "text",
                            "off",
                            "",
                            true,
                            errors
                          )}
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="wallet-box-row">
                        <MDBCol className="col-5 wallet-box-label">
                          {t("Holder Name")} :
                        </MDBCol>
                        <MDBCol>
                          {formikHelper.renderInput(
                            "holder_name",
                            "",
                            "text",
                            "off",
                            "",
                            true,
                            errors
                          )}
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="wallet-cashout-box-row">
                        <MDBCol className="col-5 wallet-box-label">
                          {t("Amount")} :
                        </MDBCol>
                        <MDBCol>
                          {formikHelper.renderInput(
                            "amount",
                            "",
                            "number",
                            "off",
                            "",
                            false,
                            errors
                          )}
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="wallet-btn">
                        {formikHelper.renderButton(
                          t("Submit"),
                          "",
                          isValid,
                          "modalbox-btn"
                        )}
                      </MDBRow>
                    </MDBContainer>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <MDBContainer className="wallet-box">
              <MDBRow className="loader-container">
              <img
                className="loader"
                src={Loader}
                alt=""
                width="50"
                height="50"
              />
              {/* LOADING.... */}
            </MDBRow>
          </MDBContainer>
          )}  
          <MDBContainer className="wallet-box-content">
                <label>{t("Check your")}</label>
                <h3>{t("Withdrawal Detail")}</h3>
                <ul>
                  <li>{t("Turnover Left")} <span>{accountDetail.turnover_left}</span></li>
                  <li>{t("Daily Withdraw Times Left")} <span>{accountDetail.daily_withdrawal_left}</span></li>
                  <li>{t("Only allow 3 transaction withdraw per day.")}</li>
                  <li>{t("One time withdrawal requires 5-10 minutes.")}</li>
                  <li>{t("One time withdrawal minimum RM20,Maximum RM100000")}</li>
                  <li>{t("Time for withdrawal from 11am to 10.30pm.")}</li>
                  <li>{t("Please contact our Customer Service if you have issue with your withdrawal")}</li>
                </ul>
            </MDBContainer>
        </MDBContainer>
      </MDBContainer>
    </React.Fragment>
  );
};

export default CashOut;
