import React, { useEffect } from "react";
import { MDBContainer, MDBRow, MDBBtn } from "mdb-react-ui-kit";
import { Logo, BGLogin } from "../../assets/images/index";
import { useNavigate, NavLink, useSearchParams } from "react-router-dom";
import authService from "../../services/auth.services";
import { useTranslation } from "react-i18next";

const Welcome = () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    validateAuth();
  });

  const validateAuth = async () => {
    const { token } = await authService.getCurrentUser();
    if (token) {
      navigate("/home");
    }
  };

  return (
    <MDBContainer
      style={{
        backgroundImage: `url(${BGLogin})`,
      }}
      className="welcome-container size-control g-0"
    >
      <MDBRow className="welcome-logo-row g-0">
        {/* <span style={{ marginTop: "10em" }}>
          Server will be closed for Chinese New Year 21 January 2023 until 26
          January 2023.
        </span> */}
        <img src={Logo} style={{ marginTop: 0 }}></img>
        <span className="font-x-large">{t("Welcome")}</span>
      </MDBRow>
      <MDBRow className="welcome-btn-row g-0">
        <MDBBtn
          className="welcome-btn-login"
          onClick={() => navigate("/login")}
        >
          <NavLink to="/login" className="non-decoration">
            {t("Login")}
          </NavLink>
        </MDBBtn>
        <NavLink to="/register" className="non-decoration">
          <MDBBtn className="welcome-btn-register">
            {t("Register an account")}
          </MDBBtn>
        </NavLink>
      </MDBRow>
    </MDBContainer>
  );
};

export default Welcome;
