import React from "react";
import Input from "./../components/element/input";
import TextArea from "./../components/element/textarea";
import Select from "./../components/element/select";
import RadioButton from "./../components/element/radioButton";
import Checkboxes from "./../components/element/checkboxes";
import Button from "./../components/element/button";
import MobileInput from "./../components/element/mobile.input";
import Selectv2 from "./../components/element/selectv2";

function renderInput(
  name,
  label,
  type = "text",
  autoComplete = "off",
  className,
  readOnly,
  errors,
  ...rest
) {
  return (
    <Input
      name={name}
      label={label}
      type={type}
      autoComplete={autoComplete}
      className={className}
      readOnly={readOnly}
      errors={errors}
      {...rest}
    />
  );
}
function renderTextArea(name, label, ...rest) {
  return <TextArea name={name} label={label} {...rest} />;
}
function renderSelect(name, label, options, errors, ...rest) {
  return (
    <Select
      name={name}
      label={label}
      options={options}
      errors={errors}
      {...rest}
    />
  );
}
function renderSelectv2(name, label, options, ...rest) {
  return <Selectv2 name={name} label={label} options={options} {...rest} />;
}
function renderRadioButton(name, label, options, ...rest) {
  return <RadioButton name={name} label={label} options={options} {...rest} />;
}
function renderCheckboxes(name, label, options, ...rest) {
  return <Checkboxes name={name} label={label} options={options} {...rest} />;
}
function renderButton(label, isSubmitting, isValid, className) {
  return (
    <Button
      label={label}
      isSubmitting={isSubmitting}
      isValid={isValid}
      className={className}
    />
  );
}
function renderMobileInput(name, placeholder, setFieldValue, errors, ...rest) {
  return (
    <MobileInput
      name={name}
      placeholder={placeholder}
      setFieldValue={setFieldValue}
      errors={errors}
      {...rest}
    />
  );
}

export default {
  renderInput,
  renderTextArea,
  renderSelect,
  renderSelectv2,
  renderRadioButton,
  renderMobileInput,
  renderButton,
  renderCheckboxes,
};
