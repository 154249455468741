import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import ResultBox from "./result.box";
import { LiveDot, UserIcon } from "../../../assets/images";
import apiServices from "./../../../services/api.services";
import { set } from "lodash";
import { useTranslation } from "react-i18next";

const Result = ({ betResult, userOnline }) => {
  // console.log("user", userOnline);
  // const [betResult, setBetResult] = useState([]);
  const [playerNumber, setPlayerNumber] = useState(0);
  const { t } = useTranslation()

  useEffect(() => {
    if (userOnline !== "-") {
      setPlayerNumber(Math.floor(Math.random() * 40 + userOnline + 500));
    }
  }, [userOnline]);

  const Counter = () => {
    if (playerNumber <= 550) {
      setPlayerNumber(Math.floor(playerNumber + 1));
    } else {
      setPlayerNumber(Math.floor(Math.random() * 40 + userOnline + 550));
    }
  };

  // useEffect(() => {
  //   constructResult();
  // },[])

  useEffect(() => {
    const min = 5;
    const max = 10;
    const random = Math.floor(Math.random() * (max - min + 1) + min);
    const interval = setInterval(() => Counter(), random * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [playerNumber]);

  // const constructResult = async () => {
  //   const response = await apiServices.getHome();
  //   if (response) {
  //     const betResultRes = response.data.bet_result || [];
  //     let runRow = 1;
  //     let runCol = 1;
  //     let arrResult = [];
  //     for (let index = 0; index < betResultRes.length; index++) {
  //       const element = betResultRes[index];
  //       const obj = {
  //         col: runCol >= 8 ? 7 : runCol,
  //         row: runRow >= 8 ? 7 : runRow,
  //         value: element,
  //       };
  //       arrResult.push(obj);
  //       if (runRow < 8) runRow++;

  //       if (runRow === 8 && runCol < 8) {
  //         runRow = 1;
  //         runCol++;
  //       }
  //     }
  //     setBetResult(arrResult);
  //   }
  // };

  // setInterval(function () {
  //   setPlayerNumber(Math.round(Math.random() * 10 * 10));
  // }, 1000 * 5);

  // function update() {
  //   setPlayerNumber(Math.round(Math.random() * 10 * 10));
  // }
  // $interval(update, 1000 * 5);

  return (
    <MDBContainer className="home-result-container g-0">
      <div className="home-result">
        <MDBRow className="home-result-live-row">
          <MDBCol className="home-result-live-row label">{t("10min Table")}</MDBCol>
          <MDBCol></MDBCol>
          <MDBCol>
            <img className="blink-image" src={LiveDot} alt=""></img>
          </MDBCol>
        </MDBRow>
        <MDBRow className="align-center">
          <ResultBox betResult={betResult} />
        </MDBRow>
        <MDBRow className="home-result-trade-row">
          <MDBCol className="home-result-trade-col">
            <img src={UserIcon} alt="" /> {playerNumber}
          </MDBCol>
          <MDBCol>
            <NavLink to="/bet/10">
              <MDBBtn>{t("Trade Now")}</MDBBtn>
            </NavLink>
          </MDBCol>
          <MDBCol>RM1 - 50000</MDBCol>
        </MDBRow>
      </div>
      <span className="home-result-trade-remark">
        {t("Pay attention to self-protection, there is only one company, beware of being deceived, For your health, please arrange your time reasonably, and enjoy a healthy life")}
      </span>
    </MDBContainer>
  );
};

export default Result;
