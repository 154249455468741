import React from "react";
import { MDBNavbar, MDBContainer, MDBCol, MDBRow } from "mdb-react-ui-kit";
import {
  Home,
  HomeBlue,
  Trend,
  TrendBlue,
  Profile,
  ProfileBlue,
  Arrow,
  ArrowBlue,
} from "../../assets/images";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation()

  const handleNavigate = (url) => {
    navigate(url);
  };

  return (
    <MDBNavbar fixed="bottom" className="navbar-bottom" bgColor="light">
      <MDBContainer className="navbar-container">
        <MDBCol
          className="navbar-col"
          size="3"
          onClick={() => handleNavigate("/home")}
        >
          <img
            className={location.pathname === "/home" ? "active" : ""}
            src={location.pathname === "/home" ? HomeBlue : Home}
            alt=""
          />
          <span className={location.pathname === "/home" ? "label" : ""}>
            {t("Home")}
          </span>
        </MDBCol>
        <MDBCol
          className="navbar-col"
          size="3"
          onClick={() => handleNavigate("/wallet")}
        >
          <img
            src={
              location.pathname === "/wallet"
                ? ArrowBlue
                : location.pathname === "/transaction"
                ? ArrowBlue
                : location.pathname === "/topup"
                ? ArrowBlue
                : location.pathname === "/cashout"
                ? ArrowBlue
                : Arrow
            }
            className={
              location.pathname === "/wallet"
                ? "active"
                : location.pathname === "/transaction"
                ? "active"
                : location.pathname === "/topup"
                ? "active"
                : location.pathname === "/cashout"
                ? "active"
                : ""
            }
            alt=""
          />
          <span
            className={
              location.pathname === "/wallet"
                ? "label"
                : location.pathname === "/transaction"
                ? "label"
                : location.pathname === "/topup"
                ? "label"
                : location.pathname === "/cashout"
                ? "label"
                : ""
            }
          >
            {t("Transaction")}
          </span>
        </MDBCol>
        <MDBCol
          className="navbar-col"
          size="3"
          onClick={() => handleNavigate("/promotion")}
        >
          <img
            src={location.pathname === "/promotion" ? TrendBlue : Trend}
            className={location.pathname === "/promotion" ? "active" : ""}
            alt=""
          />
          <span className={location.pathname === "/promotion" ? "label" : ""}>
            {t("Promotion")}
          </span>
        </MDBCol>
        <MDBCol
          className="navbar-col"
          size="3"
          onClick={() => handleNavigate("/profile")}
        >
          <img
            src={
              location.pathname === "/profile"
                ? ProfileBlue
                : location.pathname === "/report"
                ? ProfileBlue
                : location.pathname === "/history"
                ? ProfileBlue
                : location.pathname === "/referral"
                ? ProfileBlue
                : Profile
            }
            className={
              location.pathname === "/profile"
                ? "active"
                : location.pathname === "/report"
                ? "active"
                : location.pathname === "/history"
                ? "active"
                : location.pathname === "/referral"
                ? "active"
                : ""
            }
            alt=""
          />
          <span
            className={
              location.pathname === "/profile"
                ? "label"
                : location.pathname === "/report"
                ? "label"
                : location.pathname === "/history"
                ? "label"
                : location.pathname === "/referral"
                ? "label"
                : ""
            }
          >
            {t("Profile")}
          </span>
        </MDBCol>
      </MDBContainer>
    </MDBNavbar>
  );
};

export default Navbar;
