import React, { useState, useEffect, useRef } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import formikHelper from "../../../helper/formik.helper";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";
import authServices from "../../../services/auth.services";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { message } from "antd";

const BankDetailv2 = ({ bankList }) => {
  const { t } = useTranslation()
  const [ bankID, setBankID ] = useState(bankList[0].id)
  const [fileName, setFileName] = useState();
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
  const [data, setData] = useState({
    bank_number: bankList ? bankList[0].account_number : "",
    bank_name: bankList ? bankList[0].holder_name : "",
    amount: "",
    file: null,
  });

  // console.log("bankList",bankList)
  // console.log("bankID",bankID)
  // console.log("data",data)
  const schema = Yup.object({
    bank_number: Yup.string().required(t("required")),
    amount: Yup.string()
      .required(t("required"))
      // .min(20, t("minimum RM20"))
      .test("amount", t("minimum RM20"), (value) => value >= 20),     
    file: Yup.mixed()
      .required(t("required"))
      .test(
        "file",
        t("Your Image too Big"),
        (value) => value && value.size <= 1024 * 1024 * 5
      )
      .test(
        "fileType",
        t("Upload image only"),
        (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
      ),
  });

  async function onSubmit(
    data,
    setFileName,
    setData,
    setFieldError
  ) {
    const formData = new FormData();
    formData.append("amount", data.amount);
    formData.append("receipt", data.file);
    formData.append("setting_id", bankID);
    // console.log("datada",bankID)
    // console.log("formdata",data)
    try {
      const { data: response } = await authServices.Topup(
        data.amount,
        data.file,
        bankID,
        formData
      );
      // console.log("message",response.message)
      if (response) {
        const message = response.message
        setFileName("");
        setData({
          bank_number: "",
          bank_name: "",
          amount: "",
          file: null,
        });
        sweetAlertHelper({
          title: t("Notification"),
          html: t(`message`,{message}),
          showCancelButton: false,
          showConfirmButton: true,
          allowOutsideClick: false,
        });
      }
    } catch (ex) {
      setFileName("");
      setData({
        bank_number: "",
        bank_name: "",
        amount: "",
        file: null,
      });
      if (ex && ex.response.status === 422) {
        const errors = ex.response.data.errors;
        // toast.dismiss()
        if (errors && Object.keys(errors).length > 0) {
          // eslint-disable-next-line array-callback-return
          Object.keys(errors).map((item, i) => {
            setFieldError(item, errors[item]);
          });
        }
      } else {
        const message = ex.response.data.message
        sweetAlertHelper({
          title: t("Notification"),
          html: t(`message`,{message}),
          showCancelButton: false,
          showConfirmButton: true,
          allowOutsideClick: false,
        });
      }
    }
  }

  const handlerChange = (event) => {
    if (event.target.files[0].size > 1024 * 1024 * 5) {
      return setFileName("");
    } else if (event.target.files[0].type === "image/jpeg") {
      return setFileName(event.target.files[0].type);
    } else if (event.target.files[0].type === "image/png") {
      return setFileName(event.target.files[0].type);
    } else if (event.target.files[0].type === "image/jpg") {
      return setFileName(event.target.files[0].type);
    } else {
      return setFileName("");
    }
  };

  const handlerBank = (id) => {
    const i = id.selectedIndex
    setData({
      bank_number: bankList[i].account_number,
      bank_name: bankList[i].holder_name,
      amount: "",
      file: null,
    });
    setBankID(bankList[i].id)
  }

  return (
    <React.Fragment>
      <MDBContainer className="wallet-box">
        <MDBRow className="wallet-box-row">
          <MDBCol className="col-5 wallet-box-label">{t("Choose Bank")} :
          </MDBCol>
            <MDBCol>
              <MDBRow className="wallet-topup-bank">
              <select onChange={(e)=>{
                handlerBank(e.target.options)
              }}>
                {/* <option>{t("Please Select")}</option> */}
                {bankList && bankList.map((option, index) => {
                  return (
                    <option key={index} value={option.id}>
                      {option.payment_name}
                    </option>
                  );
                })}
              </select>
              </MDBRow>
            </MDBCol>
        </MDBRow>
        <Formik
          enableReinitialize={true}
          validateOnChange={true}
          initialValues={data}
          validationSchema={schema}
          onSubmit={(values, { setFieldError, resetForm }) => {
            // console.log("onsubmit", values);
            onSubmit(
              values,
              setFileName,
              setData,
              setFieldError
            );
            resetForm({ values: "" });
          }}
        >
          {({ setFieldValue, isSubmitting, handleReset, isValid, errors }) => (
            <Form>
              <MDBRow className="wallet-box-row">
                <MDBCol className="col-5 wallet-box-label">
                  {t("Bank info")} :
                </MDBCol>
                <MDBCol>
                  <MDBRow>
                    {formikHelper.renderInput(
                      "bank_name",
                      "",
                      "text",
                      "",
                      "",
                      true,
                      errors
                    )}
                  </MDBRow>
                  <MDBRow>
                    {formikHelper.renderInput(
                      "bank_number",
                      "",
                      "text",
                      "",
                      "",
                      true,
                      errors
                    )}
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              <MDBRow className="wallet-box-row">
                <MDBCol className="col-5 wallet-box-label">{t("Amount")} :</MDBCol>
                <MDBCol>
                  {formikHelper.renderInput(
                    "amount",
                    "",
                    "number",
                    "",
                    "",
                    false,
                    errors
                  )}
                </MDBCol>
              </MDBRow>
              <MDBRow className="wallet-box-row">
                <MDBCol className="col-5 wallet-box-label">
                  {t("Upload Resit")} :
                </MDBCol>
                <MDBCol>
                  <MDBRow className="upload-btn-wrapper">
                    <button className="btn">{t("MAX 2MB IMAGE")}</button>
                    <input
                      id="file"
                      name="file"
                      type="file"
                      className="custom-file-input"
                      accept="image/*"
                      onChange={(event) => {
                        setFieldValue("file", event.target.files[0]);
                        handlerChange(event);
                      }}
                    />
                    {fileName && (
                      <input
                        className={
                          fileName === "Uploaded file is too big."
                            ? "text-danger text-right no-padding error-message"
                            : fileName === "Upload image only"
                            ? "text-danger text-right no-padding error-message"
                            : "no-padding error-message"
                        }
                        name="file_name"
                        label="file name"
                        placeholder=""
                        readOnly
                        value={fileName}
                      />
                    )}
                    {errors.file ? ( <div className="text-danger text-right no-padding error-message">{errors.file}</div>) : null}                  
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              <MDBRow className="wallet-btn">
                {formikHelper.renderButton(
                  t("Submit"),
                  isSubmitting,
                  isValid,
                  "modalbox-btn"
                )}
              </MDBRow>
            </Form>
          )}
        </Formik>
      </MDBContainer>
    </React.Fragment>
  );
};

export default BankDetailv2;
