import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { DatePicker, Space } from "antd";
import "antd/dist/antd.min.css";
import styled from "styled-components";
import moment from "moment";
import { useTranslation } from "react-i18next";
import SideBar from "./../../components/sidebars/sidebar-one";
import Header from "./../../components/headers/header-three";
import Layout from "./../../components/common/layout";
import Navbar from "./../../components/element/navbar";
import Loader from "../../assets/images/Loader.svg";

// Image //
import { BGMain } from "./../../assets/images/index";

import apiServices from "../../services/api.services";
import ProtectedRoute from "../../components/common/protected.route";

export const StyleWrapperDatePicker = styled.div`
  .ant-picker-panel {
    &:last-child {
      width: 0;
      .ant-picker-header {
        position: absolute;
        right: 0;
        .ant-picker-header-prev-btn,
        .ant-picker-header-view {
          visibility: hidden;
        }
      }

      .ant-picker-body {
        display: none;
      }

      @media (min-width: 1000px) {
        width: 280px !important;
        .ant-picker-header {
          position: relative;
          .ant-picker-header-prev-btn,
          .ant-picker-header-view {
            visibility: initial;
          }
        }

        .ant-picker-body {
          display: block;
        }
      }
    }
  }
`;

const TradeHistory = () => {
  const { t } = useTranslation()
  const [endDate, setEndDate] = useState(moment().add(1, "days"));
  const [startDate, setStartDate] = useState(moment().subtract(0, "days"));
  const [tradeReport, setTradeReport] = useState();
  //header & sidebar
  const [profileInfo, setProfileInfo] = useState({});
  const [username, setUsername] = useState("");
  const [registerDate, setRegisterDate] = useState({});
  const [balance, setBalance] = useState(0);

  useEffect(() =>{ 
    getWalletBalance()
    getAccountDetailList()
  },[])

  const getAccountDetailList = async () => {
    const response = await apiServices.getAccountDetail();
    if (response) {
      setProfileInfo(response.data);
      setRegisterDate(moment(response.register_at).format("ddd MMM DD YYYY"));
      setUsername(response.data.name);
    }
  };

  const getWalletBalance = async () => {
    const response = await apiServices.getWalletBalance();
    if (response) {
      setBalance(response.data.wallet_balance);
    }
  };

  useEffect(() => {
    getTradeReport();
  }, [startDate, endDate]);

  const getTradeReport = async () => {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    const response = await apiServices.getTradeReport(start, end);
    if (response) {
      setTradeReport(response.data);
    }
  };
  // console.log("trade report ", tradeReport);
  return (
    <React.Fragment>
      <MDBContainer
        style={{ background: "#0F1620" }}
        className="profile-container g-0"
      >
        <ProtectedRoute />
        <Layout />
        <Navbar />
        <Header username={username} balance={balance}/>
        <SideBar profileInfo={profileInfo} registerDate={registerDate} balance={balance}/>
        <MDBContainer className="profile-trade-container">
          <MDBContainer>
            <MDBRow>
              <h1>{t("Full Trading History")}</h1>
            </MDBRow>
          </MDBContainer>
          <MDBContainer className="profile-trade-box">
            <MDBContainer>
              {
                tradeReport && tradeReport.length > 0 ? 
                <>
                  <div className="vl"></div>
                  <div className="v2"></div>
                  <div className="v3"></div>
                  <div className="v4"></div>
                </>
                : ""
              }
              <MDBRow className="profile-trade-box-title">
                <MDBCol className="col-2">{t("Table")}</MDBCol>
                <MDBCol className="col-3 p-0">{t("Prize No")}</MDBCol>
                <MDBCol className="col-2">{t("Trade Amount")}</MDBCol>
                <MDBCol className="col-2">{t("Result")}</MDBCol>
                <MDBCol className="col-3">{t("Trade Details")}</MDBCol>
              </MDBRow>
            </MDBContainer>
            <div className="line"></div>
            {tradeReport && tradeReport.length > 0 ? (
              <MDBContainer className="profile-trade-box-content scroll">
                {tradeReport.map((reportlist, index) => (
                  <div key={index}>
                    <MDBRow className="profile-trade-box-content-row">
                      <MDBCol className="col-2 p-0">
                        {reportlist.table}{t("min")}
                      </MDBCol>
                      <MDBCol className="col-3 p-1">
                        {reportlist.prize_no}
                      </MDBCol>
                      <MDBCol className="col-2 p-0">
                        {/* {parseFloat(reportlist.trade_amount).toFixed(0)} */}
                        {reportlist.trade_amount}
                        {/* 1000000000000 */}
                      </MDBCol>
                      <MDBCol
                        className="col-2 p-0"
                        style={{ whiteSpace: "pre-line" }}
                      >
                        {reportlist.win_amount === "0" ? (
                          <>
                            <span className="bold-text">
                              {reportlist.bet_result}
                            </span>
                            <br />
                            {t("Lose")}
                          </>
                        ) : reportlist.win_amount === "" ? (
                          <>
                           {t("Pending")}
                          </>
                        ) : (
                          <>
                            <span className="bold-text">
                              {reportlist.bet_result}
                            </span>
                            <br />
                            {t("Win")}
                            <br />
                            {reportlist.win_amount}
                          </>
                        )}
                      </MDBCol>
                      <MDBCol className="col-3">
                        <MDBRow className="box">
                          {reportlist.bet_number.map((ball, index) => (
                            <MDBCol key={index} className="col bet">
                              <div
                                className={
                                  ball === "O"
                                    ? "ball green"
                                    : ball === "S"
                                    ? "ball red"
                                    : ball === "E"
                                    ? "ball red"
                                    : ball === "B"
                                    ? "ball green"
                                    : "ball white"
                                }
                              >
                                {ball}
                              </div>
                            </MDBCol>
                          ))}
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                    <div className="line"></div>
                  </div>
                ))}
              </MDBContainer>
            ) : tradeReport && tradeReport.length === 0 ? (       
              <MDBContainer className="p-4 profile-trade-box-content scroll">
                {t("No Recent Activity")}
              </MDBContainer>
            ) : (
              <MDBContainer className="p-4 profile-trade-box-content scroll">
                  <MDBRow className="loader-container">
                    <img
                      className="loader"
                      src={Loader}
                      alt=""
                      width="50"
                      height="50"
                    />
                  </MDBRow>
              </MDBContainer>
          )}
          </MDBContainer>
        </MDBContainer>
      </MDBContainer>
    </React.Fragment>
  );
};

export default TradeHistory;
