import NewsTicker from "react-advanced-news-ticker";
import { WinnerIcon } from "./../../../assets/images";
import winnerListing from "./../../../assets/listing/winner.listing.json"
import { faker } from "@faker-js/faker";
import { useTranslation } from "react-i18next";

const WinnerListing = () => {
  const winnerName = winnerListing.name.sort(()=> 0.5 - Math.random());
  const { t } = useTranslation()
  
  // console.log(winnerName)
  return (
    <div className="home-winner-listing">
      <span className="home-winner-title-1">{t("Player")}</span>
      <span className="home-winner-title-2">{t("Total Win")}</span>

      <NewsTicker className="home-ticker">

        {winnerName.map((listing,index) => {
          // console.log("listing",listing)
          return(
            <div key={index} className="home-ticker-row">
              <img className="home-ticker-image" src={WinnerIcon} alt="" />
              <span className="home-ticker-name">{listing}</span>
              <span className="home-ticker-amount">
                {/* RM {Math.floor(Math.random() * 9999)}.{Math.floor(Math.random() * 99)} */}
                RM {winnerListing.amount[Math.floor(Math.random() * winnerListing.amount.length)]}
              </span>
            </div>
          )
        })}


        {/* <div className="home-ticker-row">
          <img className="home-ticker-image" src={WinnerIcon} alt="" />
          <span className="home-ticker-name">{faker.name.findName()}</span>
          <span className="home-ticker-amount">
            RM {Math.floor(Math.random() * 9999)}.00
          </span>
        </div>
        <div className="home-ticker-row">
          <img className="home-ticker-image" src={WinnerIcon} alt="" />
          <span className="home-ticker-name">{faker.name.findName()}</span>
          <span className="home-ticker-amount">
            RM {Math.floor(Math.random() * 9999)}.00
          </span>
        </div>
        <div className="home-ticker-row">
          <img className="home-ticker-image" src={WinnerIcon} alt="" />
          <span className="home-ticker-name">{faker.name.findName()}</span>
          <span className="home-ticker-amount">
            RM {Math.floor(Math.random() * 9999)}.00
          </span>
        </div> */}
      </NewsTicker>
    </div>
  );
};

export default WinnerListing;
