import React, { useState, useEffect, useRef } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import formikHelper from "../../../helper/formik.helper";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";
import authServices from "../../../services/auth.services";
import _ from "lodash";
import { Trans, useTranslation } from "react-i18next";
import Loader from "../../../assets/images/Loader.svg"


const Fpx = ({setShow}) => {
    const { t } =useTranslation()
    const [ data , setData ] = useState({
      amount: "",
    })
    const schema = Yup.object({
      amount: Yup.string()
      .required(t("required"))
      // .min(20, t("minimum RM20"))
      .test("amount", t("minimum RM100"), (value) => value >= 100),   
    });

  async function onSubmit(
    data,
    setFieldError,
    setShow
  ) {
    const formData = new FormData();
    formData.append("amount", data.amount);
    try {
      const { data: response } = await authServices.FpxTopup(
        data.amount,
        formData
      );
      if (response) {
        setShow(true)
        window.location.replace(response.data.p_url)
        // sweetAlertHelper({
        //   title: t("Notification"),
        //   html: t(`Topup Request Send`),
        //   showCancelButton: false,
        //   showConfirmButton: true,
        //   allowOutsideClick: false,
        // });
      }
    } catch (ex) {
      if (ex && ex.response.status === 422) {
        const errors = ex.response.data.errors;
        // toast.dismiss()
        if (errors && Object.keys(errors).length > 0) {
          // eslint-disable-next-line array-callback-return
          Object.keys(errors).map((item, i) => {
            setFieldError(item, errors[item]);
          });
        }
      } else {
        sweetAlertHelper({
          title: t("Notification"),
          html: t(`Your Topup Request Fail.`),
          showCancelButton: false,
          showConfirmButton: true,
          allowOutsideClick: false,
        });
      }
    }
  }


  return (
    <React.Fragment>
      <MDBContainer className="wallet-box">
        <Formik
          enableReinitialize={true}
          validateOnChange={true}
          initialValues={data}
          validationSchema={schema}
          onSubmit={(values, { setFieldError, resetForm }) => {
            // console.log("onsubmit", values);
            onSubmit(
              values,
              setFieldError,
              setShow
            );
            resetForm({ values: "" });
          }}
        >
          {({ setFieldValue, isSubmitting, handleReset, isValid, errors }) => (
            <Form>
              <MDBRow className="wallet-box-row">
                <MDBCol className="col-5 wallet-box-label">{t("Amount")} :</MDBCol>
                <MDBCol>
                  {formikHelper.renderInput(
                    "amount",
                    "",
                    "number",
                    "",
                    "",
                    false,
                    errors
                  )}
                </MDBCol>
              </MDBRow>
              <MDBRow className="fpx-content">
                <Trans>
                  <ul>
                    <li>{t("The amount of transaction for top up must be minimun Rm100")}</li>
                  </ul>
                </Trans>
              </MDBRow>
              <MDBRow className="wallet-btn">
                {formikHelper.renderButton(
                  t("Submit"),
                  isSubmitting,
                  isValid,
                  "modalbox-btn"
                )}
              </MDBRow>
            </Form>
          )}
        </Formik>
      </MDBContainer>
    </React.Fragment>
  );
};

export default Fpx;
