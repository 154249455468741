import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import moment from "moment";

// Common Component //
import Header from "./../../components/headers/header-three";
import SideBar from "../../components/sidebars/sidebar-one";
import Layout from "../../components/common/layout";
import Navbar from "../../components/element/navbar";
import ProtectedRoute from "../../components/common/protected.route";

// Local Component //

// Image //
import { BGMain } from "./../../assets/images/index";
import Image from "../../assets/images/Rectangle 26.png";
import Loader from "../../assets/images/Loader.svg";

// Services //
import apiServices from "../../services/api.services";
import { useTranslation } from "react-i18next";

const Promotion = () => {
  const [promotion, setPromotion] = useState();
  //header & sidebar
  const [profileInfo, setProfileInfo] = useState({});
  const [username, setUsername] = useState("");
  const [registerDate, setRegisterDate] = useState({});
  const [balance, setBalance] = useState(0);

  const { t } = useTranslation()
  useEffect(() => {
    getPromotionDetail();
  }, []);

  useEffect(() =>{ 
    getWalletBalance()
    getAccountDetailList()
  },[])

  const getPromotionDetail = async () => {
    const response = await apiServices.getPromotion();
    if (response) {
      setPromotion(response.data);
    }
  };

  const getAccountDetailList = async () => {
    const response = await apiServices.getAccountDetail();
    if (response) {
      setProfileInfo(response.data);
      setRegisterDate(moment(response.register_at).format("ddd MMM DD YYYY"));
      setUsername(response.data.name);
    }
  };

  const getWalletBalance = async () => {
    const response = await apiServices.getWalletBalance();
    if (response) {
      setBalance(response.data.wallet_balance);
    }
  };

  return (
    <React.Fragment>
      <MDBContainer className="promotion-container g-0">
        <ProtectedRoute />
        <Layout />
        <Header username={username} balance={balance}/>
        <SideBar profileInfo={profileInfo} registerDate={registerDate} balance={balance}/>
        <Navbar />
        {promotion ? (
          <>
            <MDBContainer className="promotion-section">
              {promotion.map((data,index) => {
                return (
                    <MDBCol key={index} className="promotion-section-item bg">
                      <MDBContainer id={data.id}>
                        <MDBRow className="promotion-section-item-image d-block">
                          <img src={data.media}></img>
                        </MDBRow>
                        <MDBRow>
                          <h1>{data.name}</h1>
                          <h6>{t("Terms & Conditions")}</h6>
                        </MDBRow>
                        <MDBRow className="promotion-description">
                          <span>{data.content}</span>
                        </MDBRow>
                      </MDBContainer>
                    </MDBCol>
                );
              })}
            </MDBContainer>
          </>
        ) : (
          <MDBRow className="loader-container">
            <img
              className="loader"
              src={Loader}
              alt=""
              width="50"
              height="100"
            />
            {/* LOADING.... */}
          </MDBRow>
        )}
      </MDBContainer>
    </React.Fragment>
  );
};

export default Promotion;
