import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
const CommissionRecord = ({ referralReport }) => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <MDBContainer>
        <MDBRow className="profile-box-row g-0">
          <MDBRow className="g-0">
            <div className="profile-box-label-1">{t("Commission Record")}</div>
          </MDBRow>
          <MDBContainer className="profile-box">
            {referralReport ? (
              <React.Fragment>
                {referralReport.previous_month_commission.map((p,index) => {
                  return (
                    <div key={index}>
                      <MDBRow className="profile-box-row">
                        <MDBCol className="col-6 profile-box-col">
                          {p.month} :
                        </MDBCol>
                        <MDBCol className="col-6 profile-box-col2">
                          {p.commission}
                        </MDBCol>
                      </MDBRow>
                      <div className="line"></div>
                    </div>
                  );
                })}
                {/* <MDBRow className="profile-box-row">
                  <MDBCol className="col-6 profile-box-col">qwe</MDBCol>
                  <MDBCol className="col-6 profile-box-col2">weqw</MDBCol>
                </MDBRow> */}
              </React.Fragment>
            ) : (
              ""
            )}
          </MDBContainer>
        </MDBRow>
      </MDBContainer>
    </React.Fragment>
  );
};

export default CommissionRecord;
