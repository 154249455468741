import React, { useState, useEffect } from "react";

const useCustomCountDown = (duration, minute) => {
  const [counting, setCounting] = useState(0);
  const [totalCounting, setTotalCounting] = useState(0);
  const [continueCount, setContinueCount] = useState(0);
  const [initiate, setInitiate] = useState(true);
  const [resetting, setResetting] = useState(false);
  const [countDown, setCountDown] = useState(0);

  useEffect(() => {
    setCounting(1);
    setTotalCounting(1);
  }, [duration]);

  // console.log("duration", duration);
  // console.log("minite", minute);
  useEffect(() => {
    const interval = setInterval(() => {
      // setCounting(0);
      // setContinueCount(100);
      setTotalCounting(totalCounting + 1);
      // console.log("initate ", initiate);
      // console.log("continueCount ", continueCount);
      // console.log("counting ", counting);

      // Deduction //
      if (initiate) {
        // console.log(
        //   `duration : ${duration} - counting : ${counting} = `,
        //   duration - counting
        // );
        setCountDown(duration - counting);
      } else {
        setCountDown(continueCount - counting);
      }

      // Checking
      if (duration === totalCounting && initiate) {
        // console.log("omg ", totalCounting);
        setResetting(true);
        setInitiate(false);
        setTotalCounting(0);
        setCounting(0);
        setCountDown(minute * 60);
        setContinueCount(minute * 60);
      } else {
        setResetting(false);
        // Set back to 0 after 1 cycle
        // console.log("counting ", counting);
        if (minute * 60 == counting) {
          // console.log("set back to 1");
          setCounting(1);
        } else {
          setCounting(counting + 1);
        }
        // setContinueCount(minute * 60);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [counting]);

  // useEffect(() => {
  //   setCounting(1);
  // }, [getBetResultInfo]);

  // console.log("countDown", countDown);
  return getReturnValues(countDown, resetting);
};

const getReturnValues = (countDown, resetting) => {
  // calculate time left
  const minutes = Math.floor(countDown / 60);

  const seconds = countDown - minutes * 60;

  return [minutes, seconds, resetting];
};

export { useCustomCountDown };
