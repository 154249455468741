import React, { useState, useEffect } from "react";
import { MDBContainer } from "mdb-react-ui-kit";

// Common Component //
import Header from "./../../components/headers/header-three";
import SideBar from "../../components/sidebars/sidebar-one";
import Navbar from "../../components/element/navbar";
import ProtectedRoute from "../../components/common/protected.route";

// Local Component //
import Announcement from "./component/announcement";
import WinnerListing from "./component/winner.listing";
import TradingTable from "./component/trading.table";
import Result from "./component/result";
import moment from "moment";
import Layout from "../../components/common/layout";

// Services //
import apiServices from "../../services/api.services";

const Home = () => {
  const [betResult, setBetResult] = useState([]);
  const [tradingTable, setTradingTable] = useState({});
  const [userOnline, setUserOnline] = useState("-");
  //header & sidebar
  const [profileInfo, setProfileInfo] = useState({});
  const [username, setUsername] = useState("");
  const [registerDate, setRegisterDate] = useState({});
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    getTradingTable();
    getWalletBalance();
    getAccountDetailList();
  }, []);

  const getTradingTable = async () => {
    const response = await apiServices.getHome();
    if (response) {
      const betResultRes = response.data.bet_result || [];
      let runRow = 1;
      let runCol = 1;
      let arrResult = [];
      for (let index = 0; index < betResultRes.length; index++) {
        const element = betResultRes[index];
        const obj = {
          col: runCol >= 8 ? 7 : runCol,
          row: runRow >= 8 ? 7 : runRow,
          value: element,
        };
        arrResult.push(obj);
        if (runRow < 8) runRow++;

        if (runRow === 8 && runCol < 8) {
          runRow = 1;
          runCol++;
        }
      }
      setBetResult(arrResult);
      setUserOnline(response.data.total_user_online);
      setTradingTable(response.data.table);
    }
  };

  const getAccountDetailList = async () => {
    const response = await apiServices.getAccountDetail();
    if (response) {
      setProfileInfo(response.data);
      setRegisterDate(moment(response.register_at).format("ddd MMM DD YYYY"));
      setUsername(response.data.name);
    }
  };

  const getWalletBalance = async () => {
    const response = await apiServices.getWalletBalance();
    if (response) {
      setBalance(response.data.wallet_balance);
    }
  };

  return (
    <React.Fragment>
      {/* <ProtectedRoute> </ProtectedRoute> */}
      <MDBContainer className="home-container g-0">
        <ProtectedRoute />
        <Layout />
        <Header username={username} balance={balance} />
        <SideBar
          profileInfo={profileInfo}
          registerDate={registerDate}
          balance={balance}
        />
        <Navbar />
        <Announcement className={`home-carousel`} />
        <TradingTable tradingTable={tradingTable} />
        <WinnerListing />
        <Result betResult={betResult} userOnline={userOnline} />
      </MDBContainer>
    </React.Fragment>
  );
};

export default Home;
