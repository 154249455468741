import React from "react";
import { Field, ErrorMessage } from "formik";
import { MDBRow } from "mdb-react-ui-kit";

const Input = ({
  name,
  label,
  type,
  autoComplete,
  className = "",
  readOnly,
  setFieldValue,
  errors,
  ...rest
}) => {
  return (
    <>
      <MDBRow className={readOnly ? "custom-field-code" : "custom-field"}>
        <label htmlFor={name}> {label} </label>
        <Field
          className={`form-control ${className}`}
          name={name}
          type={type}
          autoComplete={autoComplete}
          readOnly={readOnly}
          setfieldvalue={setFieldValue}
          {...rest}
        />
      </MDBRow>
      {/* <ErrorMessage
        className="text-danger text-right no-padding error-message"
        name={name}
        component="div"
      /> */}
      {errors[name] ? (
        <div className="text-danger text-right no-padding error-message">
          {errors[name]}
        </div>
      ) : null}
    </>
  );
};

export default Input;
