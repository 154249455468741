import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit";
import ScrollTop from "./../../../components/common/scroll.top";
import BetNumber from "./bet.number";
import Loader from "../../../assets/images/Loader.svg";
import apiServices from "../../../services/api.services";
import { useTranslation } from "react-i18next";
const BetResultHistory = ({ closeHandler, minute, betHistory }) => {
  const { t } = useTranslation()
  // const [betHistory, setBetHistory] = useState({});

  // // useEffect(() => {
  // //   getBetResultInfo(minute);
  // // }, []);

  // const getBetResultInfo = async (minute) => {
  //   const { data: response } = await apiServices.getBetInfo(minute);
  //   if (response) {
  //     setBetHistory(response.bet_history);
  //   }
  // };
  return (
    <React.Fragment>
      <MDBContainer
        className="bet-result-history-container"
        onClick={closeHandler}
      >
        <MDBCard className="bet-result-card">
          <MDBRow className="bet-result-card header">
            {t("Open Prize History",{minute})}
          </MDBRow>
          <MDBCardBody>
            <MDBRow className="bet-result-card-row border-bottom">
              <MDBCol className="col-3">{t("Prize No")}</MDBCol>
              <MDBCol className="col-3">{t("Result")}</MDBCol>
              <MDBCol className="col-2">{t("Sum")}</MDBCol>
              <MDBCol className="col-2">
                <MDBRow className="bet-label line">{t("Big")}</MDBRow>
                <MDBRow className="bet-label">{t("Small")}</MDBRow>
              </MDBCol>
              <MDBCol className="col-2">
                <MDBRow className="bet-label line">{t("ODD")}</MDBRow>
                <MDBRow className="bet-label">{t("Even")}</MDBRow>
              </MDBCol>
            </MDBRow>
            {betHistory.length > 0 ? (
              <>
                {betHistory.map((p, index) => {
                  return (
                    <div key={index}>
                      <MDBRow className="bet-result-card-row content border-bottom">
                        <MDBCol className="col-3">{p.prize_no}</MDBCol>
                        <MDBCol className="col-3">
                          <BetNumber number={p.result.charAt(0)} />
                          <BetNumber number={p.result.charAt(1)} />
                          <BetNumber number={p.result.charAt(2)} />
                        </MDBCol>
                        <MDBCol className="col-2">{p.sum}</MDBCol>
                        <MDBCol className="col-2">
                          <MDBRow style={{ padding: "0.5em" }}>
                            <MDBCol className="col bet">
                              <div
                                className={
                                  p["big/small"] === "big"
                                    ? "ball green"
                                    : "ball red"
                                }
                              >
                                {p["big/small"] === "big" ? "B" : "S"}
                              </div>
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                        <MDBCol className="col-2">
                          <MDBRow style={{ padding: "0.5em" }}>
                            <MDBCol className="col bet">
                              <div
                                className={
                                  p["odd/even"] === "odd"
                                    ? "ball green"
                                    : "ball red"
                                }
                              >
                                {p["odd/even"] === "odd" ? "O" : "E"}
                              </div>
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBRow>
                    </div>
                  );
                })}
              </>
            ) : (
              <MDBRow className="loader-container">
                <img
                  className="loader"
                  src={Loader}
                  alt=""
                  width="100"
                  height="100"
                />
                {/* LOADING.... */}
              </MDBRow>
            )}
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    </React.Fragment>
  );
};

export default BetResultHistory;
