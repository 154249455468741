import React, { useEffect, useState } from "react";
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBContainer,
  MDBIcon,
  MDBCol,
} from "mdb-react-ui-kit";

import { MessageCircle } from "./../../assets/images/index";
import apiServices from "../../services/api.services";
import { useTranslation } from "react-i18next";
const Header = ({ reload,username,balance }) => {
  const [headerClass, setHeaderClass] = useState("");
  const { t } = useTranslation();

  const handleScroll = (event) => {
    const currentScrollY = window.scrollY;
    // if (currentScrollY > 5) setHeaderClass("common-header-appear")
    // else setHeaderClass("common-header-disappear")
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
  }, []);

  return (
    <header className={`common-header-main ${headerClass}`}>
      <MDBNavbar className="common-header" expand="lg" light fixed>
        <div className="common-text col-sm-8">
          {/* <span>
            {t("Credit Balance")} : RM {balance ? balance : ""}
          </span> */}
          <span>
            {username} : RM {balance ? balance : ""}
          </span>
        </div>
        <div className="common-icon col-sm-4">
          <a href="https://t.me/mct_cs">
            <img src={MessageCircle} />
          </a>
        </div>
      </MDBNavbar>
    </header>
  );
};

export default Header;
