//React element
import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol,MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader } from "mdb-react-ui-kit";
import { MDBCard, MDBCardBody, MDBCardTitle, MDBBtn } from "mdb-react-ui-kit";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import formikHelper from "../../../helper/formik.helper";
import CloseBtn from "../../../assets/images/x-circle.png";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";

// Service //
import authServices from "../../../services/auth.services";
import apiServices from "../../../services/api.services";

const bankOptions = [
  { value: "", key: "- Please Select -" },
];

const ModalBox = ({ show,setShowAddDetail, closeHandler, accountDetail, getAccountDetail }) => {
  const {t} = useTranslation()
  const [bankList, setBankList] = useState();

  useEffect(() => {
    setData({ contact_number: accountDetail.phone_number });
  }, [accountDetail]);

  useEffect(() => {
    if(show){
      getBank();
    }
  }, [show]);

  const getBank = async () => {
    const response = await apiServices.getBankList();
    if (response) {
      setBankList(response.data);
    }
  };

  const [data, setData] = useState({
    fullname: "",
    account_number: "",
    payment_name: bankList || "",
    contact_number: accountDetail.phone_number || "",
  });

  const schema = Yup.object({
    fullname: Yup.string().required(t("required")),
    account_number: Yup.string().required(t("required")).matches(/^\d+$/,t('The field should have digits only')),
    payment_name: Yup.mixed().required(t("required")),
    contact_number: Yup.string()
      .required(t("required"))
      .min(10, t("Please enter more than or equal to 10 character"))
      .max(13, t("Must be exactly 13 digits"))
      .matches(/^([6]{1}|\+?[0]{1})([0-9]{9,11})$/g, t("Must be phone format")),
  });

  async function onSubmit(
    data,
    setFieldError,
    setSubmitting,
    resetForm,
    defaultData,
    getAccountDetail,
    closeHandler
  ) {
    try {
      const { data: response } = await authServices.UpdateProfile({ ...data });
      if (response) {
        const message = response.message
        resetForm({ defaultData });
        getAccountDetail();
        closeHandler();
        sweetAlertHelper({
          title: t("Notification"),
          html: t(`message`,{message}),
          showCancelButton: false,
          showConfirmButton: true,
          allowOutsideClick: false,
        });
      }
    } catch (ex) {
      if (ex && ex.response.status === 422) {
        const errors = ex.response.data.errors;
        // toast.dismiss()
        if (errors && Object.keys(errors).length > 0) {
          // eslint-disable-next-line array-callback-return
          Object.keys(errors).map((item, i) => {
            setFieldError(item, errors[item]);
          });
        }
      } else {
        const message = ex.response.data.message
        sweetAlertHelper({
          title: t("Notification"),
          html: t(`message`,{message}),
          showCancelButton: false,
          showConfirmButton: true,
          allowOutsideClick: false,
        });
      }
    }
    setSubmitting(false);
  }

  // console.log("show",show)
    return (
      <MDBModal staticBackdrop show={show} setShow={setShowAddDetail} className="modalbox-container" backdrop="static">
        <MDBModalDialog
          className="modalbox"
            alignment="center"
            style={{ maxWidth: "22rem" }}>
            <MDBModalHeader className="modalbox-title">{t("My Bank Detail")}</MDBModalHeader>
            <MDBModalContent>
            <div className="closeBtn" >
              <img
                src={CloseBtn}
                variant="default"
                size="sm"
                width="40"
                onClick={() => closeHandler()}
              />
            </div>
            {Object.keys(accountDetail).length > 0 ? (
                <Formik
                  enableReinitialize={true}
                  validateOnChange={true}
                  initialValues={data}
                  validationSchema={schema}
                  onSubmit={(
                    values,
                    { setSubmitting, setFieldError, resetForm }
                  ) => {
                    onSubmit(
                      values,
                      setFieldError,
                      setSubmitting,
                      resetForm,
                      data,
                      getAccountDetail,
                      closeHandler,
                    );
                    resetForm({ values: "" });
                  }}
                >
                  {({ errors, isSubmitting, isValid, handleReset }) => (
                    <Form>
                      <MDBRow className="modalbox-row">
                        <MDBCol className="modalbox-label">{t("Full Name")} :</MDBCol>
                        <MDBCol className="modalbox-input">
                          {formikHelper.renderInput(
                            "fullname",
                            "",
                            "text",
                            "",
                            "",
                            false,
                            errors
                          )}
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="modalbox-row">
                        <MDBCol className="modalbox-label">
                        {t("Bank Account")} :
                        </MDBCol>
                        <MDBCol className="modalbox-input">
                          {formikHelper.renderInput(
                            "account_number",
                            "",
                            "text",
                            "",
                            "",
                            false,
                            errors
                          )}
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="modalbox-row">
                        <MDBCol className="modalbox-label">{t("Bank Type")} :</MDBCol>
                        {bankList ? (
                          <MDBCol className="modalbox-input">
                            {formikHelper.renderSelect(
                              "payment_name",
                              "",
                              bankList,
                              errors
                            )}
                          </MDBCol>
                        ) : (
                          <MDBCol className="modalbox-input">
                          {formikHelper.renderSelect(
                            "payment_name",
                            "",
                            bankOptions,
                            errors
                          )}
                        </MDBCol>
                        )}
                      </MDBRow>
                      <MDBRow className="modalbox-row">
                        <MDBCol className="modalbox-label">{t("Contact Number")} :</MDBCol>
                        <MDBCol className="modalbox-input g-0">
                          {formikHelper.renderInput(
                            "contact_number",
                            "",
                            "number",
                            "",
                            "modalbox-input-mobile",
                            true,
                            errors
                          )}
                        </MDBCol>
                      </MDBRow>
                      {formikHelper.renderButton(
                        t("Submit"),
                        isSubmitting,
                        isValid,
                        "modalbox-btn"
                      )}
                    </Form>
                  )}
                </Formik>
            ):""}
            </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    );
};

export default ModalBox;