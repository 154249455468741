import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import { DatePicker } from "antd";
import styled from "styled-components";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import useInfiniteScroll from "./component/use.infinite.scroll";

// Common Component //
import Header from "../../components/headers/header-three";
import SideBar from "../../components/sidebars/sidebar-one";
import ProtectedRoute from "../../components/common/protected.route";

// Image //
import { BGMain } from "../../assets/images/index";
import Navbar from "../../components/element/navbar";
import Layout from "../../components/common/layout";
import Loader from "../../assets/images/Loader.svg";
import apiServices from "../../services/api.services";
import { useTranslation } from "react-i18next";
export const StyleWrapperDatePicker = styled.div`
  .ant-picker-panel {
    &:last-child {
      width: 0;
      .ant-picker-header {
        position: absolute;
        right: 0;
        .ant-picker-header-prev-btn,
        .ant-picker-header-view {
          visibility: hidden;
        }
      }

      .ant-picker-body {
        display: none;
      }

      @media (min-width: 1000px) {
        width: 280px !important;
        .ant-picker-header {
          position: relative;
          .ant-picker-header-prev-btn,
          .ant-picker-header-view {
            visibility: initial;
          }
        }

        .ant-picker-body {
          display: block;
        }
      }
    }
  }
`;

const Transaction = () => {
  const { t } =useTranslation()
  const [endDate, setEndDate] = useState(moment().add(1, "days").format("YYYY-MM-DD"));
  const [startDate, setStartDate] = useState(moment().subtract(0, "days").format("YYYY-MM-DD"));
  const [start, setStart] = useState(0);
  const [showRemark, setShowRemark] = useState();
  const [walletDetail, setWalletDetail] = useState([]);
  const [isEnd, setIsEnd] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreListItems);
  const [loading, setLoading] = useState(true);
  const [onLoad, setOnLoad] = useState(true);
  //header & sidebar
  const [profileInfo, setProfileInfo] = useState({});
  const [username, setUsername] = useState("");
  const [registerDate, setRegisterDate] = useState({});
  const [reload, setReload ] = useState(false)
  const [balance, setBalance] = useState(0);

  const panelRender = (panelNode) => (
    <StyleWrapperDatePicker>{panelNode}</StyleWrapperDatePicker>
  );

  async function fetchMoreListItems() {
    let sumStart = start + 10; // skip 10 every load
    const response = await apiServices.getWalletHistory(
      10,
      start,
      startDate,
      endDate,
    );
    if (response.data.length > 0) {
      setWalletDetail((listItems) => [...listItems, ...response.data]);
    } else {
      setIsEnd(true);
    }
    setStart(sumStart);
    setIsFetching(false);
    setLoading(false);
    setOnLoad(false);
  }

  const getWalletDetail = async (start = 0) => {
    setWalletDetail([]);
    setIsEnd(false);
    let sumStart = start + 10; // skip 10 every load
    const response = await apiServices.getWalletHistory(
      10,
      start,
      startDate,
      endDate,
    );
    if (response.data.length > 0) {
      setWalletDetail(response.data);
    } else {
      setIsEnd(true);
    }
    setStart(sumStart);
    setIsFetching(false);
    setLoading(false);
    setOnLoad(false);
  };

  const getWalletBalance = async () => {
    const response = await apiServices.getWalletBalance();
    if (response) {
      setBalance(response.data.wallet_balance);
    }
  };

  const getAccountDetailList = async () => {
    const response = await apiServices.getAccountDetail();
    if (response) {
      setProfileInfo(response.data);
      setRegisterDate(moment(response.register_at).format("ddd MMM DD YYYY"));
      setUsername(response.data.name);
    }
  };

  const handleScroll = useCallback(async () => {
    if (isEnd) {
      return;
    }
    if (
      document.documentElement.scrollTop + window.innerHeight >=
      document.scrollingElement.scrollHeight
    ) {
      setIsFetching(true);
      // scrollToBottom();
    } else {
      return;
    }
  }, []);

  useEffect(() =>{ 
    getWalletBalance()
    getAccountDetailList()
  },[])

  useEffect(() => {
    getWalletDetail();
  }, [startDate, endDate]);

  useEffect(() => {
    if (!onLoad) {
      if (!isEnd) {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
      } else {
        window.removeEventListener("scroll", handleScroll, true);
      }
    }
    return;
  }, [isEnd, onLoad]);

  // console.log("detail",walletDetail)
  return (
    <React.Fragment>
      <MDBContainer className="wallet-container">
        <ProtectedRoute />
        <Layout />
        <Header reload={reload} username={username} balance={balance}/>
        <SideBar profileInfo={profileInfo} registerDate={registerDate} balance={balance}/>
        <Navbar />
        <MDBContainer className="wallet-transaction-container2">
          <MDBContainer>
            <MDBRow className="wallet-transaction-label">
              <label>{t("Transaction History")}</label>
            </MDBRow>
            {/* <DatePicker.RangePicker
              panelRender={panelRender}
              onChange={(values, dateString) => {
                setStartDate(dateString[0]);
                setEndDate(dateString[1]);
              }}
              // onOpenChange={onCloseDateRange}
              defaultValue={[moment().subtract(2, "days"), moment()]}
              disabled={true}
            /> */}
          </MDBContainer>
          <MDBContainer className="wallet-transaction-history">
            <MDBRow className="wallet-transaction-row">
              <MDBCol className="col-4">{t("Date")}</MDBCol>
              <MDBCol className="col-4">{t("Amount")}</MDBCol>
              <MDBCol className="col-4">{t("Status")}</MDBCol>
            </MDBRow>
            <div className="line"></div>
            <MDBContainer className="scroll">
              {!loading && walletDetail.length > 0 ? (
                walletDetail.map((p, index) => (
                  <div key={index}>
                    <MDBRow
                      className="wallet-transaction-row"
                      id={p.id}
                      onClick={() => setShowRemark(p.id)}
                    >
                      <MDBCol className="col-4">
                        {p.date}/{moment().format("YYYY")}
                        <br />
                        {p.time}
                      </MDBCol>
                      <MDBCol className={p.type === "topup" 
                      ? "col-4 wallet-transaction-status-topup" 
                      : p.type === "withdrawal" 
                      ? "col-4 wallet-transaction-status-withdrawl"
                      : "wallet-transaction-status-other"}>
                        {p.amount.charAt(0) !== "-"? "+" + p.amount : p.amount }
                        </MDBCol>
                      <MDBCol
                        className={
                          p.status === "pending"
                            ? "col-4 wallet-transaction-status-pending"
                            : p.status === "active" && p.type === "topup"
                            ? "col-4 wallet-transaction-status-topup"
                            : p.status === "active" && p.type === "withdrawal"
                            ? "wallet-transaction-status-withdrawl"
                            : p.status === "fail"
                            ? "col-4 wallet-transaction-status-fail"
                            : "wallet-transaction-status-other"
                        }
                      >
                        {p.description}
                      </MDBCol>
                    </MDBRow>
                    {showRemark == p.id && p.status == "fail" && (
                      <div className="wallet-transaction-remark">
                        {t("Remark")} 
                        {" "}{p.remark ? p.remark.substring(0, 20) : "no remark"}
                      </div>
                    )}
                    <div className="line"></div>
                  </div>
                ))
              ) : loading ? (
                <MDBRow className="loader-container">
                  <img
                    className="loader"
                    src={Loader}
                    alt=""
                    width="50"
                    height="100"
                  />
                  {/* LOADING.... */}
                </MDBRow>
              ) : (
                ""
              )}
              {isFetching && (
                <MDBRow className="loader-container-2">
                  <img
                    className="loader"
                    src={Loader}
                    alt=""
                    width="25"
                    height="60"
                  />
                  {/* LOADING.... */}
                </MDBRow>
              )}

              {isEnd && (
                <MDBRow className="d-block p-4">{t("Loading Complete")}</MDBRow>
              )}
            </MDBContainer>
          </MDBContainer>
        </MDBContainer>
      </MDBContainer>
    </React.Fragment>
  );
};

export default Transaction;
