import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import Loader from "../../../assets/images/Loader.svg"
//local Component//
import BankDetailv2 from "./bank.detail";
import Fpx from "./fpx";

const TopupMethod = ({bankList}) => {
    const { t } = useTranslation();
    const [ showFpx, setShowFpx ] =useState(false)
    const [ showDeposit, setShowDeposit ] = useState(false)
    const [ show, setShow ] =useState(false)
    const handleClick = (type) => {
        if(type === "fpx"){
            setShowFpx(!showFpx)
            setShowDeposit(false)
        }else if(type === "deposit"){
            setShowDeposit(!showDeposit)
            setShowFpx(false)
        }
    }
    // console.log("topup",show)
  return (
    <React.Fragment>
        {show && <MDBContainer className="loader-container3">
            <div className="loader">
                <div>
                    <img
                        src={Loader}
                        alt=""
                        width="50"
                        height="100"
                    />          
                </div>
            </div>
        </MDBContainer>}
        <MDBContainer className="wallet-topup-container">
            <MDBRow>
                <MDBBtn 
                onClick={()=>{
                    handleClick("fpx")
                }}
                className="wallet-topup-btn">{t("FPX")}</MDBBtn>
            </MDBRow> 
        </MDBContainer>
            {showFpx  && <Fpx setShow={setShow} />}
        <MDBContainer className="wallet-topup-container">
            <MDBRow>
                <MDBBtn onClick={()=>{
                    handleClick("deposit")
                }}className="wallet-topup-btn">{t("Submit Deposit")}</MDBBtn>
            </MDBRow>                    
        </MDBContainer>
            {showDeposit && bankList && <BankDetailv2 bankList={bankList}/>}
    </React.Fragment>
  );
};

export default TopupMethod;
