import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MDBContainer } from "mdb-react-ui-kit";
import moment from "moment";

// Common Component //
import Header from "../../components/headers/header-three";
import SideBar from "../../components/sidebars/sidebar-one";
import ProtectedRoute from "../../components/common/protected.route";

// Local Component //
import BetResult from "./component/bet.result";
import BetPlace from "./component/bet.place";
import Layout from "../../components/common/layout";
import BetResultHistory from "./component/bet.result.history";
import BetConfirm from "./component/confirm";
import BetHowToPlay from "./component/bet.how.to.play";

//Services
import apiServices from "../../services/api.services";

const Bet = () => {
  const { min } = useParams();
  const navigate = useNavigate()
  const [showResultHistory, setShowResultHistory] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showHowToPlay, setShowHowToPlay] = useState(false);
  const [reload, setReload] = useState(false);
  const [betHistory, setBetHistory] = useState({});
  const [myBetHistory, setMyBetHistory] = useState({});
  const [prizeRate, setPrizeRate] = useState([]);
  //bet_result
  const [lastOpenPrize, setLastOpenPrize] = useState("");
  const [currentBet, setCurrentBet] = useState({});
  const [countdown, setCountdown] = useState(false);
  const [first, setFirst] = useState(0);
  const [second, setSecond] = useState(0);
  const [third, setThird] = useState(0);
  const [numbers, setNumbers] = useState([]);
  const [flipFlag, setFlipFlag] = useState(false);
  const [counting, setCounting] = useState(0);
  const [winnerUsername, setWinnerUsername] = useState(12345);
  //header & sidebar
  const [profileInfo, setProfileInfo] = useState({});
  const [username, setUsername] = useState("");
  const [registerDate, setRegisterDate] = useState({});
  const [balance, setBalance] = useState(0);
  //active table bet
  const [tableActive,setTableActive] = useState([])

  useEffect(() => {
    getBetResultInfo(min);
    getWalletBalance()
    getAccountDetailList()
    getHomeTable()
  }, []);

  const getAccountDetailList = async () => {
    const response = await apiServices.getAccountDetail();
    if (response) {
      setProfileInfo(response.data);
      setRegisterDate(moment(response.register_at).format("ddd MMM DD YYYY"));
      setUsername(response.data.name);
    }
  };

  const getWalletBalance = async () => {
    const response = await apiServices.getWalletBalance();
    if (response) {
      setBalance(response.data.wallet_balance);
    }
  };

  const getHomeTable = async () => {
    const response = await apiServices.getHome()
    if(response){
      setTableActive(response.data.table)
    }
  }

  // console.log("curr", currentBet);
  const getBetResultInfo = async (min) => {
    try {
      const { data: response } = await apiServices.getBetInfo(min);
      if (response) {
        setBetHistory(response.bet_history);
        setMyBetHistory(response.my_bet_history);
        setPrizeRate(response.bet_prizes);
        //bet_result
        setLastOpenPrize(response.last_open_prize);
        setCurrentBet(response.current_bet);
        setCountdown(true);
        setFirst(response.bet_history[0].result[0]);
        setSecond(response.bet_history[0].result[1]);
        setThird(response.bet_history[0].result[2]);
        setNumbers(response.last_3_number);
        setFlipFlag(false);
        setWinnerUsername(response.username);
      }
    }catch(ex){
      if (ex.response.status === 422) {
        navigate("/home")
      }
    }
  };

  const closeBox = () => {
    setShowResultHistory(false);
    setShowConfirm(false);
    setShowHowToPlay(false);
  };

  const showResultBox = () => {
    setShowResultHistory(true);
  };

  const showConfirmBox = () => {
    setShowConfirm(true);
  };

  const showHowToPlayBox = () => {
    setShowHowToPlay(true);
  };

  return (
    <React.Fragment>
      {showResultHistory && (
        <BetResultHistory
          minute={min}
          closeHandler={closeBox}
          betHistory={betHistory}
        />
      )}
      {showConfirm && <BetConfirm closeHandler={closeBox} />}
      {showHowToPlay && <BetHowToPlay closeHandler={closeBox} />}
      <MDBContainer className="bet-page">
        <MDBContainer className="betting-container g-0">
          <ProtectedRoute />
          <Layout />
          <Header username={username} balance={balance}/>
          <SideBar profileInfo={profileInfo} registerDate={registerDate} balance={balance}/>
          <BetResult
            minute={min}
            show={showResultBox}
            getBetResultInfo={getBetResultInfo}
            lastOpenPrize={lastOpenPrize}
            currentBet={currentBet}
            first={first}
            second={second}
            third={third}
            flipFlag={flipFlag}
            countdown={countdown}
            setFlipFlag={setFlipFlag}
            numbers={numbers}
            // counting={counting}
            // setCounting={setCounting}
          />
        </MDBContainer>
        <BetPlace
          minute={min}
          myBetHistory={myBetHistory}
          prizeRate={prizeRate}
          getBetResultInfo={getBetResultInfo}
          show={showHowToPlayBox}
          getWalletBalance={getWalletBalance}
          tableActive={tableActive}
        />
      </MDBContainer>
    </React.Fragment>
  );
};

export default Bet;
