import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import { bubble as Menu } from "react-burger-menu";
import { SidebarLogoV2 } from "../../assets/images";
import moment from "moment";
import _, { set } from "lodash";
import { useNavigate } from "react-router-dom";
import apiServices from "../../services/api.services";
import authServices from "../../services/auth.services";
import { useTranslation } from "react-i18next";
const SideBar = ({ reload,profileInfo,registerDate,balance }) => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  // const [balance, setBalance] = useState(0);
  const [iconList, setIconList] = useState([
    {
      text: t("Home"),
      icon: "home",
      active: false,
      url: "/home",
    },
    {
      text: t("Promotion"),
      icon: "chart-line",
      active: false,
      url: "/promotion",
    },
    {
      text: t("Today Report"),
      icon: "layer-group",
      active: false,
      url: "/report",
    },
    {
      text: t("Trade History"),
      icon: "flag",
      active: false,
      url: "/history",
    },
    {
      text: t("My Referral"),
      icon: "users",
      active: false,
      url: "/referral",
    },
    {
      text: t("Sign Out"),
      icon: "sign-out-alt",
      active: false,
      url: "/signout",
    },
  ]);
  const [show, setShow] = useState(false);

  const handleClickEvent = (url) => {
    if (_.isEqual(url, "/signout")) {
      authServices.logout();
      navigate("/");
    } else {
      // navigate
      navigate(url);
    }
  };

  return (
    <Menu>
      <MDBContainer className="sidebar-container">
        <MDBRow className="sidebar-logo-row">
          <img src={SidebarLogoV2} alt="" />
        </MDBRow>
        <MDBRow className="sidebar-info-row">
          <MDBCol className="sidebar-info-player">
            <span className="sidebar-info-player-name">
              {Object.keys(profileInfo).length > 0
                ? profileInfo.name || ""
                : ""}
            </span>
            <span className="sidebar-info-register">
              {t("register at",{registerDate})}
              {/* {Object.keys(profileInfo).length > 0
                ? new Date(profileInfo.register_at).toDateString() || 0
                : 0} */}
            </span>
          </MDBCol>
          <MDBCol className="sidebar-info-vip">
            <span className="sidebar-info-vip-text">
              VIP{" "}
              {Object.keys(profileInfo).length > 0
                ? profileInfo.vip_rank || 0
                : 0}
            </span>
          </MDBCol>
        </MDBRow>
        <MDBRow className="sidebar-info-wallet">
          <MDBRow className="sidebar-info-wallet-box g-0">
            <MDBCol className="sidebar-info-left" size={6}>
              <span className="sidebar-info-balance">{t("My Balance")}</span>
              <span className="sidebar-info-wallet-amount">
                RM {balance ? balance : 0}{" "}
              </span>
            </MDBCol>
            <MDBCol className="sidebar-info-right" size={6}>
              <span
                className="sidebar-info-topup"
                onClick={() => {
                  navigate("/topup");
                }}
              >
                {t("Top up")}
              </span>
            </MDBCol>
          </MDBRow>
        </MDBRow>

        <MDBRow className="sidebar-menu g-0">
          {iconList.map((data, index) => (
            <MDBRow
              key={`sidebar-row-${index}`}
              className={`sidebar-icon-row ${
                window.location.pathname === data.url ? "active" : ""
              }`}
              onClick={() => {
                handleClickEvent(data.url);
              }}
            >
              <MDBIcon
                className="sidebar-menu-icon"
                fas
                size="lg"
                icon={data.icon}
              />
              <span className="sidebar-menu-text"> {data.text} </span>
            </MDBRow>
          ))}
        </MDBRow>
      </MDBContainer>
    </Menu>
  );
};

export default SideBar;
