import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBRadio } from "mdb-react-ui-kit";

//Element
import Checkboxes from "../../../components/element/checkboxes";
import { useTranslation } from "react-i18next"


const ChangeLanguage = () => {
  const {t,i18n} = useTranslation()
  const selected = localStorage.getItem("i18nextLng")
  const handleChange = (lang) => {
    i18n.changeLanguage(lang)
  }

  return (
    <MDBContainer>
      <MDBRow className="profile-box-row">
        <MDBRow className="g-0">
          <div className="profile-box-label-1">{t("CHANGE LANGUAGE")}</div>
        </MDBRow>
        <MDBContainer className="profile-box">
          <MDBRow className="profile-box-row">
            <MDBCol className="profile-box-label-2">English</MDBCol>
            <MDBCol>
              <MDBRadio
                className="profile-box-btn-radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                checked={selected === "en"}
                onChange={()=>{
                  handleChange("en")
                }}
              />
            </MDBCol>
          </MDBRow>
          <div className="line"></div>
          <MDBRow className="profile-box-row">
            <MDBCol className="profile-box-label-2">华语</MDBCol>
            <MDBCol>
              <MDBRadio
                className="profile-box-btn-radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                checked={selected === "zh"}
                onChange={()=>{
                  handleChange("zh")
                }} 
              />
            </MDBCol>
          </MDBRow>
          <div className="line"></div>
          <MDBRow className="profile-box-row">
            <MDBCol className="profile-box-label-2">Malay</MDBCol>
            <MDBCol>
              <MDBRadio
                className="profile-box-btn-radio"
                name="flexRadioDefault"
                id="flexRadioDefault3"
                checked={selected === "bm"}
                onChange={()=>{
                  handleChange("bm")
                }}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBRow>
    </MDBContainer>
  );
};

export default ChangeLanguage;
