import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { DatePicker } from "antd";
import styled from "styled-components";
import moment from "moment";
import { useTranslation } from "react-i18next";
import SideBar from "../../components/sidebars/sidebar-one";
import Header from "../../components/headers/header-three";
import Navbar from "../../components/element/navbar";
import Layout from "../../components/common/layout";
import ProtectedRoute from "../../components/common/protected.route";
import apiServices from "../../services/api.services";

const TodayReport = () => {
  const [endDate, setEndDate] = useState(moment().add(1, "days"));
  const [startDate, setStartDate] = useState(moment().subtract(0, "days"));
  const [todayReport, setTodayReport] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation()
  //header & sidebar
  const [profileInfo, setProfileInfo] = useState({});
  const [username, setUsername] = useState("");
  const [registerDate, setRegisterDate] = useState({});
  const [balance, setBalance] = useState(0);
  

  useEffect(() => {
    getTodayReport();
  }, [startDate, endDate]);

  const getTodayReport = async () => {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    const response = await apiServices.getTodayReport(start, end);
    if (response) {
      setTodayReport(response.data);
    }
    return response;
  };

  const onCloseDateRange = (open) => {
    setIsOpen(open);
  };

  useEffect(() =>{ 
    getWalletBalance()
    getAccountDetailList()
  },[])

  const getAccountDetailList = async () => {
    const response = await apiServices.getAccountDetail();
    if (response) {
      setProfileInfo(response.data);
      setRegisterDate(moment(response.register_at).format("ddd MMM DD YYYY"));
      setUsername(response.data.name);
    }
  };

  const getWalletBalance = async () => {
    const response = await apiServices.getWalletBalance();
    if (response) {
      setBalance(response.data.wallet_balance);
    }
  };

  return (
    <React.Fragment>
      <MDBContainer
        style={{ background: "#0F1620" }}
        className="profile-container g-0"
      >
        <ProtectedRoute />
        <Layout />
        <Header username={username} balance={balance}/>
        <SideBar profileInfo={profileInfo} registerDate={registerDate} balance={balance}/>
        <Navbar />
        {todayReport ? (
          <MDBContainer className="profile-report-main">
            <MDBRow>
              <h1>{t("Today Report")}</h1>
            </MDBRow>
            <div className="profile-report-box">
              <MDBRow>
                <h4>{t("Profit & Loss")}</h4>
              </MDBRow>
              <MDBRow
                className={
                  todayReport.profit_loss.toString().charAt(0) === "-"
                    ? "profile-report-box-price-highlight-red"
                    : "profile-report-box-price-highlight"
                }
              >
                <label>{todayReport.profit_loss}</label>
              </MDBRow>
              <div className="line"></div>
              <MDBRow>
                <MDBCol className="profile-report-box-col1">
                  <MDBCol>{t("Trade Amount")}</MDBCol>
                  <MDBCol>{todayReport.trade_prize}</MDBCol>
                </MDBCol>
                <MDBCol className="profile-report-box-col">
                  <MDBCol>{t("Win Amount")}</MDBCol>
                  <MDBCol>{todayReport.win_amount}</MDBCol>
                </MDBCol>
                <MDBCol className="profile-report-box-col1">
                  <MDBCol>{t("Event Bonus")}</MDBCol>
                  <MDBCol>{todayReport.event_bonus}</MDBCol>
                </MDBCol>
              </MDBRow>
              <div className="line"></div>
              <MDBRow>
                <MDBCol className="profile-report-box-col1">
                  <MDBCol>{t("Topup Amount")}</MDBCol>
                  <MDBCol>{todayReport.topup_amount}</MDBCol>
                </MDBCol>
                <MDBCol className="profile-report-box-col">
                  <MDBCol>{t("Withdraw Amount")}</MDBCol>
                  <MDBCol>{todayReport.withdrawal_amount}</MDBCol>
                </MDBCol>
                <MDBCol className="profile-report-box-col1">
                  <MDBCol>{t("Commision")}</MDBCol>
                  <MDBCol>{todayReport.commission}</MDBCol>
                </MDBCol>
              </MDBRow>
              <div className="line"></div>
              <MDBRow>
                <MDBCol className="profile-report-box-col1">
                  <MDBCol>{t("VIP Bonus")}</MDBCol>
                  <MDBCol>{todayReport.vip_bonus}</MDBCol>
                </MDBCol>
                <MDBCol className="profile-report-box-col">
                  <MDBCol></MDBCol>
                  <MDBCol></MDBCol>
                </MDBCol>
                <MDBCol className="profile-report-box-col1">
                  <MDBCol></MDBCol>
                  <MDBCol></MDBCol>
                </MDBCol>
              </MDBRow>
            </div>
          </MDBContainer>
        ) : (
          ""
        )}
      </MDBContainer>
    </React.Fragment>
  );
};

export default TodayReport;
