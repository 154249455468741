import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Trans,useTranslation } from "react-i18next"

const History = () => {
  const {t} = useTranslation()

  return (
    <MDBContainer>
      {/* <Referral show={showContent} /> */}
      <MDBRow className="profile-box-row">
        <MDBRow className="g-0">
          <div className="profile-box-label-1">{t("My History")}</div>
        </MDBRow>
        <MDBContainer className="profile-box">
          <MDBRow className="profile-box-row">
            <MDBCol className="col-5 profile-box-col">{t("Today Report")} :</MDBCol>
            <MDBCol className="col-7 profile-box-col2">
              <Link to="/report">{t("Click Here")}</Link>
            </MDBCol>
          </MDBRow>
          <div className="line"></div>
          <MDBRow className="profile-box-row">
            <MDBCol className="col-5 profile-box-col">{t("Trade History")} :</MDBCol>
            <MDBCol className="col-7 profile-box-col2">
              <Link to="/history">{t("Click Here")}</Link>
            </MDBCol>
          </MDBRow>
          <div className="line"></div>
          <MDBRow className="profile-box-row">
            <MDBCol className="col-5 profile-box-col">{t("My Referral")} :</MDBCol>
            <MDBCol className="col-7 profile-box-col2">
              <Link to="/referral">{t("Click Here")}</Link>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBRow>
    </MDBContainer>
  );
};

export default History;
