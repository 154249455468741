import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
// import Loader from "../../../assets/images/Loader.svg";
import TradingBox from "./../component/trading.box";
import { useNavigate } from "react-router-dom";
import apiServices from "../../../services/api.services";
import { useTranslation } from "react-i18next";

const TradingTable = ({ tradingTable }) => {
  var numberPattern = /\d+/g;
  const navigate = useNavigate();
  const { t } = useTranslation()
  // const [tradingTable, setTradingTable] = useState({});

  // useEffect(() => {
  //   getTradingTable();
  // }, []);

  // const getTradingTable = async () => {
  //   const response = await apiServices.getHome();
  //   if (response) {
  //     setTradingTable(response.data.table);
  //   }
  // };

  const handleClick = (minute) => {
    navigate("/bet/" + minute);
  };

  return (
    <MDBContainer className="home-trading-container g-0">
      <MDBRow className="home-trading-row g-0">
        <MDBRow className="g-0">
          <div className="home-trading-label-1">{t("Start Trade Today")}</div>
          <div className="home-trading-label-2">{t("Trading Table")}</div>
        </MDBRow>
        <MDBRow className="g-0">
          {tradingTable.length > 0 ? (
            <>
              {tradingTable.map((listing, index) => {
                console.log("listing",listing)
                for (var key in listing){
                  return (
                    <MDBCol
                      key={index}
                      onClick={(e) => {
                        handleClick(key);
                      }}
                      className="g-0"
                    >
                      <TradingBox minute={key} />
                    </MDBCol>
                  );
                }
              })}
            </>
          ) : (
            ""
          )}
        </MDBRow>
      </MDBRow>
    </MDBContainer>
  );
};

export default TradingTable;
