import React from "react";
import { Link } from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
const Action = () => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <MDBContainer className="wallet-action-container">
        <MDBRow>
          <MDBRow className="wallet-action-label first">{t("Action")}</MDBRow>
        </MDBRow>
        <MDBRow>
          <MDBCol className="wallet-action-box">
            <Link to="/topup">
              <label>{t("i like to")}</label>
              <h2>{t("Top up")}</h2>
              <span>{t("Minimum topup is Rm20")}</span>
              <br />
              <span>{t("in 5min will credit to wallet")}</span>
            </Link>
          </MDBCol>
          <MDBCol className="wallet-action-box">
            <Link to="/cashout">
              <label>{t("i want to")}</label>
              <h2>{t("Cash out")}</h2>
              <span>{t("Minimum withdraw is Rm20")}</span>
              <br />
              <span>{t("after finish x1 turnover")}</span>
            </Link>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </React.Fragment>
  );
};

export default Action;
