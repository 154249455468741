import React, { useEffect, useState } from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import BetCountdown from "./bet.countdown";
import BetBubble from "./bet.bubble";
import BetNumber from "./bet.number";
import authServices from "./../../../services/auth.services";
import apiServices from "../../../services/api.services";
import { useCustomCountDown } from "./use.custom.countdown";
import _ from "lodash";
import Pusher from "pusher-js";
import BetCustomCountdown from "./bet.custom.countdown";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import InfoIcon from "../../../assets/images/info.svg";
import config from "../../../config.json";
import { useTranslation } from "react-i18next";
// Pusher.logToConsole = true;

const BetResult = ({
  show,
  minute,
  getBetResultInfo,
  lastOpenPrize,
  currentBet,
  first,
  second,
  third,
  flipFlag,
  countdown,
  numbers,
  setFlipFlag,
  counting,
  setCounting,
}) => {
  const { t } = useTranslation();
  // const [betHistory, setBetHistory] = useState({});
  // const [flipFlag, setFlipFlag] = useState(false);
  const [spin, setSpin] = useState(false);
  const [shouldStop, setShouldStop] = useState(false);
  // const [countdown, setCountdown] = useState(true);
  // const [lastOpenPrize, setLastOpenPrize] = useState("");
  // const [currentBet, setCurrentBet] = useState({});
  // const [first, setFirst] = useState(0);
  // const [second, setSecond] = useState(0);
  // const [third, setThird] = useState(0);
  // const [numbers, setNumbers] = useState([]);
  const [openNumber, setOpenNumber] = useState("");
  const [customStop, setCustomStop] = useState(false);
  const [duration, setDuration] = useState(currentBet.duration);
  // const [winnerUsername, setWinnerUsername] = useState(12345);
  const socketEndPoint = config.socketEndPoint;

  // console.log("last price", lastOpenPrize);
  const [minutes, seconds, resetting] = useCustomCountDown(
    currentBet.duration,
    minute,
    getBetResultInfo
    // counting,
    // setCounting
  );

  // console.log("minutes", minutes);
  // console.log("seconds", seconds);
  // const getBetResultInfo = async (minute) => {
  //   const { data: response } = await apiServices.getBetInfo(minute);
  //   if (response) {
  //     // setBetHistory(response.bet_history);
  //     setLastOpenPrize(response.last_open_prize);
  //     setCurrentBet(response.current_bet);
  //     setCountdown(true);
  //     setFirst(response.bet_history[0].result[0]);
  //     setSecond(response.bet_history[0].result[1]);
  //     setThird(response.bet_history[0].result[2]);
  //     setNumbers(response.last_3_number);
  //     setFlipFlag(false);
  //     setWinnerUsername(response.username);
  //   }
  // };
  // console.log("123", winnerUsername);
  // useEffect(() => {
  //   getBetResultInfo(minute);
  // }, []);

  useEffect(() => {
    const pusher = new Pusher(config.socketApiKey, {
      cluster: config.socketCluster,
      authEndpoint: config.socketEndPoint,
      auth: {
        headers: {
          Authorization: "Bearer " + authServices.getCurrentUser().token,
          Accept: "application/json",
        },
      },
    });

    const channel = pusher.subscribe("presence-bet-type-" + minute);
    const count = channel.members.count;

    var socketId = null;
    pusher.connection.bind("connected", () => {
      socketId = pusher.connection.socket_id;
    });

    // channel.bind("create-bet-event", (data) => {
    //   // console.log("create event ", data);
    //   const result = data.data;
    //   if (result) {
    //     // console.log("Start loading ...");
    //     // setFlipFlag(true);
    //     // setSpin(true);
    //     // setShouldStop(false);
    //   }
    // });
    channel.bind("release-bet-event", (data) => {
      // console.log("release event ", data);
      const result = data.data;
      const MySwal = withReactContent(Swal);
      setCustomStop(true);
      if (result) {
        setOpenNumber(result.bet_result);
        setShouldStop(true);
        // console.log("result", result);
        setTimeout(() => {
          setSpin(false);
          getBetResultInfo(minute);
        }, 1500);
        if (
          result.bet_status === "win" &&
          data.winner_username.toLowerCase() ===
            localStorage.getItem("username").toLowerCase()
        ) {
          // console.log("Congrats ! Your had won the trade !");
          // sweetAlertHelper({
          //   title: "Notification",
          //   html: `Congratulation ! You had won ${result.win_amount}`,
          //   showCancelButton: false,
          //   showConfirmButton: false,
          // });
          // MySwal.fire({
          //   title: "Notification",
          //   html: `Congratulation ! You had won ${result.win_amount}`,
          //   showCancelButton: false,
          //   showConfirmButton: true,
          //   allowOutsideClick: false,
          //   customClass: {
          //     title: "custom-sweet-alert-title",
          //   },
          // }).then((result) => {
          //   window.location.reload(false);
          // });
        } else if (result.bet_status == undefined) {
          MySwal.fire({
            title: t("Notification"),
            html: t(`New trade had released. Please click to refresh`),
            showCancelButton: false,
            showConfirmButton: true,
            allowOutsideClick: false,
            customClass: {
              title: "custom-sweet-alert-title",
            },
          }).then((result) => {
            window.location.reload(false);
          });
          // console.log("Stop loading ...");
          // setOpenNumber(result.bet_result);
          // setShouldStop(true);

          // setTimeout(() => {
          //   setSpin(false);
          //   getBetResultInfo(minute);
          // }, 1500);
        }
      }
    });
    const winBetChannel = pusher.subscribe(
      "presence-user-id-" + localStorage.getItem("user_id")
    );

    var winSocketId = null;
    pusher.connection.bind("connected", () => {
      winSocketId = pusher.connection.socket_id;
    });
    winBetChannel.bind("win-bet-event", (data) => {
      // console.log("release event ", data);
      const resultAmount = data.data.win_amount;
      const result = data.data;
      const MySwal = withReactContent(Swal);
      if (result) {
        if (
          result.bet_status === "win" &&
          data.winner_username.toLowerCase() ===
            localStorage.getItem("username").toLowerCase()
        ) {
          MySwal.fire({
            title: t("Notification"),
            html: t(`Congratulation ! You had won`, { resultAmount }),
            showCancelButton: false,
            showConfirmButton: true,
            allowOutsideClick: false,
            customClass: {
              title: "custom-sweet-alert-title",
            },
          }).then((result) => {
            window.location.reload(false);
          });
        }
      }
    });
    return () => {
      channel.unbind("release-bet-event");
      channel.unbind("win-bet-event");
    };
  }, []);

  return (
    <React.Fragment>
      {/* <MDBContainer className="betting-result-container"> */}
      <MDBRow className="bet-result-row g-0">
        <MDBCol className="bet-last-prize" onClick={show}>
          <img src={InfoIcon} alt="" width="20em" />
          <div className="bet-last-prize-first">
            {t("Last Open Prize", { lastOpenPrize })}
          </div>
          <div className="bet-last-prize-second">
            <BetNumber
              number={`${first}`}
              flipFlag={flipFlag}
              shouldStop={shouldStop}
              setShouldStop={setShouldStop}
              spin={spin}
              initialSpeed={100}
              openNumber={openNumber[0]}
            />

            <BetNumber
              number={`${second}`}
              flipFlag={flipFlag}
              shouldStop={shouldStop}
              setShouldStop={setShouldStop}
              spin={spin}
              initialSpeed={200}
              openNumber={openNumber[1]}
            />
            <BetNumber
              number={`${third}`}
              flipFlag={flipFlag}
              shouldStop={shouldStop}
              setShouldStop={setShouldStop}
              spin={spin}
              initialSpeed={300}
              openNumber={openNumber[2]}
            />
          </div>
        </MDBCol>
        <MDBCol className="bet-open-prize">
          <div className="bet-open-prize-first">
            {t("Current Open Prize")} : {currentBet.prize_no}
          </div>
          <div className="bet-open-prize-second">
            {countdown ? (
              <BetCustomCountdown
                minutes={minutes}
                seconds={seconds}
                resetting={resetting}
                flipFlag={flipFlag}
                setFlipFlag={setFlipFlag}
                setSpin={setSpin}
                setShouldStop={setShouldStop}
                customStop={customStop}
              />
            ) : (
              <div style={{ whiteSpace: "nowrap" }}>
                <span className="bet-timer">0</span>
                <span className="bet-timer">0</span>:
                <span className="bet-timer">0</span>
                <span className="bet-timer">0</span>
              </div>
            )}
          </div>
        </MDBCol>
      </MDBRow>
      <BetBubble minute={minute} numbers={numbers} />
      {/* </MDBContainer> */}
    </React.Fragment>
  );
};

export default BetResult;
