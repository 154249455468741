import React, { useState, useEffect } from "react";
import { MDBContainer } from "mdb-react-ui-kit";
import Box from "./box";
import _ from "lodash";

const ResultBox = ({ betResult }) => {
  const [row, setRow] = useState([1, 2, 3, 4, 5, 6, 7]);
  const [col, setCol] = useState([1, 2, 3, 4, 5, 6, 7]);

  useEffect(() => {}, []);
  return (
    <MDBContainer className="home-result-box-container d-flex flex-wrap justify-content-evenly">
      {row.map((rowValue) => {
        return col.map((colValue, index) => {
          const keyValue = _.find(betResult, (data) => {
            return data.col === colValue && data.row === rowValue;
          });
          return (
            <Box
              key={`box-${index}`}
              row={rowValue}
              col={colValue}
              keyValue={keyValue}
            />
          );
        });
      })}
    </MDBContainer>
  );
};

export default ResultBox;
