import React, { useState, useEffect, Suspense } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import moment from "moment";

// Common Component //
import Header from "./../../components/headers/header-three";
import SideBar from "./../../components/sidebars/sidebar-one";
import Layout from "./../../components/common/layout";
import Navbar from "./../../components/element/navbar";
import ProtectedRoute from "../../components/common/protected.route";

// Local Component //
import Action from "./component/action";
import TransactionHistory from "../Transaction/component/transaction.history";

// Image //
import { BGMain } from "./../../assets/images/index";
import apiServices from "../../services/api.services";

const Wallet = () => {
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  // const [reload, setReload] = useState(false);
  const [length, setLength] = useState(5);
  const [walletDetail, setWalletDetail] = useState();
  // const TransactionHistory = React.lazy(() => {
  //   return new Promise((resolve) => setTimeout(resolve, 1000)).then(() =>
  //     import("../Transaction/component/transaction.history")
  //   );
  // });
  const [profileInfo, setProfileInfo] = useState({});
  const [username, setUsername] = useState("");
  const [registerDate, setRegisterDate] = useState({});
  const [reload, setReload ] = useState(false)
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    getWalletDetail();
    getAccountDetailList();
    getWalletBalance()
  }, []);

  const getWalletBalance = async () => {
    const response = await apiServices.getWalletBalance();
    if (response) {
      setBalance(response.data.wallet_balance);
    }
  };

  const getAccountDetailList = async () => {
    const response = await apiServices.getAccountDetail();
    if (response) {
      setProfileInfo(response.data);
      setRegisterDate(moment(response.register_at).format("ddd MMM DD YYYY"));
      setUsername(response.data.name);
    }
  };

  const getWalletDetail = async () => {
    const response = await apiServices.getWalletHistory(
      // endDate,
      // startDate,
      length
    );
    if (response) {
      setWalletDetail(response.data);
    }
  };

  return (
    <React.Fragment>
      <MDBContainer className="wallet-container g-0">
        <ProtectedRoute />
        <Layout />
        <Header reload={reload} username={username} balance={balance}/>
        <SideBar profileInfo={profileInfo} registerDate={registerDate} balance={balance}/>
        <Navbar />
        <Action />
        {/* <Suspense fallback={<h1 style={{ color: "white" }}>Loading...</h1>}>
          {walletDetail && <TransactionHistory walletDetail={walletDetail} />} */}
        <TransactionHistory walletDetail={walletDetail} />
        {/* </Suspense> */}
      </MDBContainer>
    </React.Fragment>
  );
};

export default Wallet;
