import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next"

const AccountDetail = ({ show, accountDetail }) => {
  const { t } = useTranslation()
  return (
    <MDBContainer>
      <MDBRow className="profile-box-row">
        <MDBRow className="g-0">
          <div className="profile-box-label-1">{t("My Account Detail")}</div>
        </MDBRow>
        <MDBContainer className="profile-box">
          <MDBRow className="profile-box-row">
            <MDBCol className="col-5 profile-box-col">{t("Full Name")} :</MDBCol>
            <MDBCol className="col-7 profile-box-col2">
              {accountDetail.fullname ? (
                <MDBCol className="col-6 profile-box-col2">
                  {accountDetail.fullname || ""}
                </MDBCol>
              ) : (
                <button onClick={show}>+ {t("Add Detail")}</button>
              )}
            </MDBCol>
          </MDBRow>
          <div className="line"></div>
          <MDBRow className="profile-box-row">
            <MDBCol className="col-5 profile-box-col">{t("Bank Account")} :</MDBCol>
            <MDBCol className="col-7 profile-box-col2">
              {accountDetail.bank_account_number || ""}
            </MDBCol>
          </MDBRow>
          <div className="line"></div>
          <MDBRow className="profile-box-row">
            <MDBCol className="col-5 profile-box-col">{t("Bank Type")} :</MDBCol>
            <MDBCol className="col-7 profile-box-col2">
              {accountDetail.bank_type || ""}
            </MDBCol>
          </MDBRow>
          <div className="line"></div>
          <MDBRow className="profile-box-row">
            <MDBCol className="col-5 profile-box-col">{t("Contact Number")} :</MDBCol>
            <MDBCol className="col-7 profile-box-col2">
              {accountDetail.phone_number || ""}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <MDBRow className="profle-box-comment">
          {t("*Full name must same with Bank Account Holder Name")}
        </MDBRow>
      </MDBRow>
    </MDBContainer>
  );
};

export default AccountDetail;
