import { MDBContainer, MDBRow, MDBIcon } from "mdb-react-ui-kit"
import { useNavigate, NavLink } from "react-router-dom"
import { useTranslation } from "react-i18next"
const Header = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <MDBContainer className="login-header g-0">
      <MDBRow>
        <MDBIcon
          className="login-back-icon"
          fas
          size="lg"
          icon="angle-left"
          onClick={() => navigate("/")}
        />
        <NavLink
          className="login-text no-decoration text-color-white"
          to="/register"
        >
          <span>{t("Register ID")}</span>
        </NavLink>
      </MDBRow>
    </MDBContainer>
  )
}

export default Header
