import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import moment from "moment";

// Common Component //
import Header from "../../components/headers/header-three";
import SideBar from "../../components/sidebars/sidebar-one";
import Navbar from "../../components/element/navbar";

//local Component//
import TopupMethod from "./component/topup.method";

// Image //
import Layout from "../../components/common/layout";

import apiServices from "../../services/api.services";
import ProtectedRoute from "../../components/common/protected.route";
import { Trans, useTranslation } from "react-i18next";
const TopUpv2 = () => {
  const [bankList, setBankList] = useState();
  const [profileInfo, setProfileInfo] = useState({});
  const [username, setUsername] = useState("");
  const [registerDate, setRegisterDate] = useState({});
  const [reload, setReload ] = useState(false)
  const [balance, setBalance] = useState(0);
  const { t } = useTranslation()

  useEffect(() => {
    getBankList();
  }, []);

  useEffect(() => {
    getAccountDetailList();
    getWalletBalance()
  }, []);

  const getBankList = async () => {
    const response = await apiServices.getCompanyBankList();
    setBankList(response.data);
  };

  const getWalletBalance = async () => {
    const response = await apiServices.getWalletBalance();
    if (response) {
      setBalance(response.data.wallet_balance);
    }
  };

  const getAccountDetailList = async () => {
    const response = await apiServices.getAccountDetail();
    if (response) {
      setProfileInfo(response.data);
      setRegisterDate(moment(response.register_at).format("ddd MMM DD YYYY"));
      setUsername(response.data.name);
    }
  };

  return (
    <React.Fragment>
      <MDBContainer className="wallet-container">
        <ProtectedRoute />
        <Layout />
        <Header reload={reload} username={username} balance={balance}/>
        <SideBar profileInfo={profileInfo} registerDate={registerDate} balance={balance}/>
        <Navbar />
        <MDBContainer style={{ paddingTop: "5em", paddingBottom: "10em" }}>
          <MDBRow>
            <MDBCol className="wallet-title">{t("Choose Topup Method")}</MDBCol>
          </MDBRow>
          <TopupMethod bankList={bankList}/>
          <MDBContainer className="wallet-box-content">
            <Trans>
              <label>{t("Must Read")}</label>
              <h3>{t("Notice")}</h3>
              <ul>
                <li>{t("The receipt reference must fill in your name or company name(MCT) to be accept. If you enter any sensitive word, it will be rejected and not able to refund")}</li>
                <li>{t("The amount of transaction for top up must be minimum RM20")}</li>
                <li>{t("Make sure to upload the bank slip must be clear and in full size receipt.")}</li>
                <li>{t("The bank slip receipt must be uploaded into the platform within 24 Hours or else the receipt consider Void.")}</li>
                <li>{t("Time for upload bank slip from 11am to 10.30pm.")}</li>
              </ul>
            </Trans>
          </MDBContainer>
        </MDBContainer>
      </MDBContainer>
    </React.Fragment>
  );
};

export default TopUpv2;
