import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import Loader from "../../../assets/images/Loader.svg";
import { useTranslation } from "react-i18next";
const TransactionHistory = ({ walletDetail }) => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <MDBContainer className="wallet-transaction-container ">
        <MDBRow className="wallet-action-label">{t("Transaction History")}</MDBRow>
        <MDBContainer className="wallet-transaction-history">
          <MDBRow className="wallet-transaction-row title">
            <MDBCol className="col-4">{t("Date")}</MDBCol>
            <MDBCol className="col-4">{t("Amount")}</MDBCol>
            <MDBCol className="col-4">{t("Status")}</MDBCol>
          </MDBRow>
          <div className="line"></div>
          {walletDetail && walletDetail.length > 0 ? (
            <>
              {walletDetail.map((p, index) => (
                <div key={index}>
                  <MDBRow className="wallet-transaction-row data">
                    <MDBCol className="col-4">
                      {p.date}/{moment().format("YYYY")}
                      <br />
                      {p.time}
                    </MDBCol>
                    <MDBCol className={p.type === "topup" 
                      ? "col-4 wallet-transaction-status-topup" 
                      : p.type === "withdrawal" 
                      ? "col-4 wallet-transaction-status-withdrawl"
                      : "wallet-transaction-status-other"}>
                        {p.amount.charAt(0) !== "-" ? "+" + p.amount : p.amount }</MDBCol>
                    <MDBCol
                      className={
                          p.status === "pending"
                            ? "col-4 wallet-transaction-status-pending"
                            : p.status === "active" && p.type === "topup"
                            ? "col-4 wallet-transaction-status-topup"
                            : p.status === "active" && p.type === "withdrawal"
                            ? "wallet-transaction-status-withdrawl"
                            : p.status === "fail"
                            ? "col-4 wallet-transaction-status-fail"
                            : "wallet-transaction-status-other"

                        // p.description === "PENDING"
                        //   ? "col-4 wallet-transaction-status-pending"
                        //   : p.description === "W.PENDING"
                        //   ? "col-4 wallet-transaction-status-pending"
                        //   : p.description === "FAIL"
                        //   ? "col-4 wallet-transaction-status-fail"
                        //   : p.description === "W.FAIL"
                        //   ? "col-4 wallet-transaction-status-fail"
                        //   : "col-4 wallet-transaction-status-success"
                      }
                    >
                      {p.description}
                    </MDBCol>
                  </MDBRow>
                  <div className="line"></div>
                </div>
              ))}
            </>
          ) : walletDetail && walletDetail.length === 0 ? (
            <MDBRow style={{ textAlign : "center", display: "block", padding: "5em"}}>
              {t("No Recent Activity")}
            </MDBRow>
          ) : (
            <MDBRow className="loader-container">
              <img
                className="loader"
                src={Loader}
                alt=""
                width="100"
                height="100"
              />
            </MDBRow>
          )}
          <MDBRow className="wallet-transaction-btn">
            <Link to="/transaction">
              <MDBBtn>{t("TAP TO VIEW")}</MDBBtn>
            </Link>
          </MDBRow>
        </MDBContainer>
      </MDBContainer>
    </React.Fragment>
  );
};

export default TransactionHistory;
